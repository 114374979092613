import insta1 from "../../assets/images/home/insta-1.png";
import insta2 from "../../assets/images/home/insta-2.png";
import insta3 from "../../assets/images/home/insta-3.png";
import insta4 from "../../assets/images/home/insta-4.png";
import insta5 from "../../assets/images/home/insta-5.png";
import insta6 from "../../assets/images/home/insta-6.png";
import insta7 from "../../assets/images/home/insta-7.png";
import insta8 from "../../assets/images/home/insta-8.png";
import insta9 from "../../assets/images/home/insta-9.png";

export const INSTAPHOTOS =[
    insta1,insta2,insta3,insta4,insta5,insta6,insta7,insta8,insta9
]