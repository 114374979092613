// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 600px) {
  .width-sm-full {
    width: 100% !important;
  }
  .margin-top-sm {
    margin-top: 0.635rem !important;
  }
  .margin-bottom-sm {
    margin-bottom: 0.635rem !important;
  }
  .primary-sm-heading {
    font-size: 24px !important;
  }
  .secondary-sm-heading {
    font-size: 20px !important;
  }
  .tertiary-sm-heading {
    font-size: 24px !important;
  }
  .super-sm-heading {
    font-size: 40px !important;
  }
  .tertiary-sm-title {
    font-size: 12px !important;
  }
  .btn-orange {
    padding: 8px 10px !important;
  }
  .btn-orange-outline {
    padding: 8px 10px !important;
  }
  .btn-black {
    padding: 8px 10px !important;
  }
  .margin-top-sm-2 {
    margin-top: 2rem !important;
  }
  .margin-outlet-x {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .padding-top-sm-none {
    padding-top: 0px !important;
  }
  .padding-bottom-sm-none {
    padding-bottom: 0px !important;
  }
  .flex-direction-column-sm {
    flex-direction: column !important;
  }
}
@media screen and (max-width: 1200px) {
  .border-radius-50-md-left {
    border-radius: 0px 0px 0px 0px !important;
  }
  .border-radius-50-md-right {
    border-radius: 0px 0px 0px 0px !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/screen-media-query.scss"],"names":[],"mappings":"AAEA;EACE;IACE,sBAAA;EAAF;EAGA;IACE,+BAAA;EADF;EAIA;IACE,kCAAA;EAFF;EAKA;IACE,0BAAA;EAHF;EAMA;IACE,0BAAA;EAJF;EAOA;IACE,0BAAA;EALF;EAQA;IACE,0BAAA;EANF;EASA;IACE,0BAAA;EAPF;EAUA;IACE,4BAAA;EARF;EAWA;IACE,4BAAA;EATF;EAYA;IACE,4BAAA;EAVF;EAaA;IACE,2BAAA;EAXF;EAcA;IACE,mBAAA;IACA,oBAAA;EAZF;EAeA;IACE,2BAAA;EAbF;EAgBA;IACE,8BAAA;EAdF;EAiBA;IACC,iCAAA;EAfD;AACF;AAoBA;EACE;IACE,yCAAA;EAlBF;EAqBA;IACE,yCAAA;EAnBF;AACF","sourcesContent":["@import \"./variables\";\n\n@media screen and (max-width:600px) {\n  .width-sm-full {\n    width: 100% !important;\n  }\n\n  .margin-top-sm {\n    margin-top: 0.635rem !important;\n  }\n\n  .margin-bottom-sm {\n    margin-bottom: 0.635rem !important;\n  }\n\n  .primary-sm-heading {\n    font-size: $secondary-heading !important;\n  }\n\n  .secondary-sm-heading {\n    font-size: $heading-20 !important;\n  }\n\n  .tertiary-sm-heading {\n    font-size: $secondary-heading !important;\n  }\n\n  .super-sm-heading {\n    font-size: $primary-heading !important;\n  }\n\n  .tertiary-sm-title {\n    font-size: $tertiary-title !important;\n  }\n\n  .btn-orange {\n    padding: 8px 10px !important;\n  }\n\n  .btn-orange-outline {\n    padding: 8px 10px !important;\n  }\n\n  .btn-black {\n    padding: 8px 10px !important;\n  }\n\n  .margin-top-sm-2 {\n    margin-top: 2rem !important;\n  }\n\n  .margin-outlet-x {\n    margin-left: .5rem;\n    margin-right: .5rem;\n  }\n\n  .padding-top-sm-none {\n    padding-top: 0px !important;\n  }\n\n  .padding-bottom-sm-none {\n    padding-bottom: 0px !important;\n  }\n\n  .flex-direction-column-sm {\n   flex-direction: column !important;\n  }\n\n  \n}\n\n@media screen and (max-width:1200px) {\n  .border-radius-50-md-left {\n    border-radius: 0px 0px 0px 0px !important;\n  }\n  \n  .border-radius-50-md-right {\n    border-radius: 0px 0px 0px 0px !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
