// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-change {
  transition: transform 0.3s ease;
}

.text-change:hover {
  transform: scale(1.6) !important;
  color: #FF8945 !important;
}`, "",{"version":3,"sources":["webpack://./src/features/explore-recipes/exploreRecipes.scss"],"names":[],"mappings":"AAAA;EACI,+BAAA;AACJ;;AACA;EACE,gCAAA;EACA,yBAAA;AAEF","sourcesContent":[".text-change {\r\n    transition: transform 0.3s ease;\r\n}\r\n.text-change:hover {\r\n  transform: scale(1.6) !important;\r\n  color: #FF8945 !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
