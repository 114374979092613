// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.testimonials-section .swiper-pagination-custom {
  margin-top: 50px;
}
.testimonials-section .swiper-pagination-custom .swiper-pagination-bullet {
  transition: all 0.2s linear;
  width: 18px !important;
  height: 18px !important;
}
.testimonials-section .swiper-pagination-custom .swiper-pagination-bullet-active {
  background: #FF611D;
  width: 36px !important;
  border-radius: 10px !important;
}`, "",{"version":3,"sources":["webpack://./src/features/testimonials-section/testimonials-section.scss"],"names":[],"mappings":"AACI;EACI,gBAAA;AAAR;AACQ;EACI,2BAAA;EACA,sBAAA;EACA,uBAAA;AACZ;AAEQ;EACI,mBAAA;EACA,sBAAA;EACA,8BAAA;AAAZ","sourcesContent":[".testimonials-section {\n    .swiper-pagination-custom {\n        margin-top: 50px;\n        .swiper-pagination-bullet {\n            transition: all .2s linear;\n            width: 18px !important;\n            height: 18px !important;\n        }\n\n        .swiper-pagination-bullet-active {\n            background: #FF611D;\n            width: 36px !important;\n            border-radius: 10px !important;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
