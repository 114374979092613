// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/our-collection/bg-hero-reusable.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.talk-to-us-form::after {
  content: "";
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  position: absolute;
  top: -165px;
  width: 1000px;
  height: 800px;
  background-size: 100%;
  background-repeat: no-repeat;
  left: 0;
}
@media screen and (max-width: 990px) {
  .talk-to-us-form::after {
    display: none;
  }
}
.talk-to-us-form .MuiInputBase-root {
  padding: 0;
  border-radius: 8px;
}
.talk-to-us-form .MuiInputBase-root.Mui-focused fieldset {
  border-color: rgba(255, 164, 18, 0.45);
}
.talk-to-us-form .MuiInputBase-root .MuiInputBase-input {
  padding: 10px 16px;
  font-family: "Work Sans", sans-serif !important;
  color: #1e293b;
}
.talk-to-us-form .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
  border: 1.5px solid #e5e7eb;
}
.talk-to-us-form .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: rgba(255, 164, 18, 0.45);
}
.talk-to-us-form .submit-button {
  animation: changeBG 5s infinite;
}
@keyframes changeBG {
  25% {
    background-color: #1e293b;
  }
  50% {
    background-color: #ff611d;
  }
  75% {
    background-color: #1e293b;
  }
  100% {
    background-color: #ff611d;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/talk-to-us-form/talk-to-us-form.scss"],"names":[],"mappings":"AACE;EACE,WAAA;EACA,yDAAA;EACA,kBAAA;EACA,WAAA;EAEA,aAAA;EACA,aAAA;EACA,qBAAA;EACA,4BAAA;EACA,OAAA;AADJ;AAEI;EAXF;IAYI,aAAA;EACJ;AACF;AACE;EACE,UAAA;EACA,kBAAA;AACJ;AACI;EACE,sCAAA;AACN;AAEI;EACE,kBAAA;EACA,+CAAA;EACA,cAAA;AAAN;AAGI;EACE,2BAAA;AADN;AAII;EACE,sCAAA;AAFN;AAME;EACE,+BAAA;AAJJ;AAOE;EACE;IACE,yBAAA;EALJ;EAQE;IACE,yBAAA;EANJ;EASE;IACE,yBAAA;EAPJ;EAUE;IACE,yBAAA;EARJ;AACF","sourcesContent":[".talk-to-us-form {\n  &::after {\n    content: \"\";\n    background-image: url(\"../../assets/images/our-collection/bg-hero-reusable.png\");\n    position: absolute;\n    top: -165px;\n\n    width: 1000px;\n    height: 800px;\n    background-size: 100%;\n    background-repeat: no-repeat;\n    left: 0;\n    @media screen and (max-width: 990px) {\n      display: none;\n    }\n  }\n  .MuiInputBase-root {\n    padding: 0;\n    border-radius: 8px;\n\n    &.Mui-focused fieldset {\n      border-color: rgba(255, 164, 18, 0.45);\n    }\n\n    .MuiInputBase-input {\n      padding: 10px 16px;\n      font-family: \"Work Sans\", sans-serif !important;\n      color: #1e293b;\n    }\n\n    .MuiOutlinedInput-notchedOutline {\n      border: 1.5px solid #e5e7eb;\n    }\n\n    &:hover .MuiOutlinedInput-notchedOutline {\n      border-color: rgba(255, 164, 18, 0.45);\n    }\n  }\n\n  .submit-button {\n    animation: changeBG 5s infinite;\n  }\n\n  @keyframes changeBG {\n    25% {\n      background-color: #1e293b;\n    }\n\n    50% {\n      background-color: #ff611d;\n    }\n\n    75% {\n      background-color: #1e293b;\n    }\n\n    100% {\n      background-color: #ff611d;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
