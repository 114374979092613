// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_inputBox__7zzVu {
  max-width: 100%;
  width: 100%;
}
.styles_inputBox__7zzVu .styles_label__h\\+cxZ {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  display: block;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
  margin-bottom: 5px;
}
.styles_inputBox__7zzVu .styles_input__iK3XB {
  padding: 4px 10px;
  max-width: 100%;
  width: 100%;
  padding: 0;
  border: 1px solid #c4c4c4;
  z-index: 100;
  border-radius: 4px;
}
.styles_inputBox__7zzVu .styles_input__iK3XB .MuiInputAdornment-root {
  margin-left: 8px;
}
.styles_inputBox__7zzVu .styles_input__iK3XB .MuiInputAdornment-root svg {
  max-width: 20px;
}
.styles_inputBox__7zzVu .styles_input__iK3XB .MuiInputAdornment-positionEnd {
  margin-left: 0px;
  margin-right: 9px;
}
.styles_inputBox__7zzVu .styles_input__iK3XB .MuiInputBase-root input {
  padding-right: 10px;
}
.styles_inputBox__7zzVu .styles_input__iK3XB .MuiInputBase-root::before {
  display: none;
}
.styles_inputBox__7zzVu .styles_input__iK3XB .MuiInputBase-root::after {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/features/Custom-input/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,WAAA;AACF;AAAE;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,cAAA;EACA,mBAAA;EACA,gBAAA;EACA,cAAA;EACA,kBAAA;AAEJ;AAAE;EACE,iBAAA;EACA,eAAA;EACA,WAAA;EACA,UAAA;EACA,yBAAA;EACA,YAAA;EACA,kBAAA;AAEJ;AACM;EACE,gBAAA;AACR;AAAQ;EACE,eAAA;AAEV;AACM;EACE,gBAAA;EACA,iBAAA;AACR;AAEQ;EACE,mBAAA;AAAV;AAEQ;EACE,aAAA;AAAV;AAEQ;EACE,aAAA;AAAV","sourcesContent":[".inputBox {\n  max-width: 100%;\n  width: 100%;\n  .label {\n    font-size: 16px;\n    font-weight: 400;\n    line-height: 19px;\n    display: block;\n    letter-spacing: 0em;\n    text-align: left;\n    color: #000000;\n    margin-bottom: 5px;\n  }\n  .input {\n    padding: 4px 10px;\n    max-width: 100%;\n    width: 100%;\n    padding: 0;\n    border: 1px solid #c4c4c4;\n    z-index: 100;\n    border-radius: 4px;\n\n    :global {\n      .MuiInputAdornment-root {\n        margin-left: 8px;\n        svg {\n          max-width: 20px;\n        }\n      }\n      .MuiInputAdornment-positionEnd {\n        margin-left: 0px;\n        margin-right: 9px;\n      }\n      .MuiInputBase-root {\n        input {\n          padding-right: 10px;\n        }\n        &::before {\n          display: none;\n        }\n        &::after {\n          display: none;\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputBox": `styles_inputBox__7zzVu`,
	"label": `styles_label__h+cxZ`,
	"input": `styles_input__iK3XB`
};
export default ___CSS_LOADER_EXPORT___;
