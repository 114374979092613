// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-style {
  width: 288px !important;
  background-color: black;
  border-radius: 35px !important;
  display: flex;
  align-items: center;
}

.text-design {
  color: #d2ff3a !important;
}

.card-text {
  padding-bottom: 5px !important;
  margin: -5px 0 2px 10px !important;
}`, "",{"version":3,"sources":["webpack://./src/features/eat-healthy/eatHealthy.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,uBAAA;EACA,8BAAA;EACA,aAAA;EACA,mBAAA;AACF;;AACA;EACE,yBAAA;AAEF;;AAAA;EACE,8BAAA;EACA,kCAAA;AAGF","sourcesContent":[".card-style {\r\n  width: 288px !important;\r\n  background-color: black;\r\n  border-radius: 35px !important;\r\n  display: flex;\r\n  align-items: center;\r\n}\r\n.text-design {\r\n  color: #d2ff3a !important;\r\n}\r\n.card-text {\r\n  padding-bottom: 5px !important;\r\n  margin: -5px 0 2px 10px !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
