import blog1 from "../blogAssets/BlogCardImages/Blog 1.jpg";
import blog2 from "../blogAssets/BlogCardImages/Blog 2.jpg";
import blog3 from "../blogAssets/BlogCardImages/Blog 3.jpg";
import blog4 from "../blogAssets/BlogCardImages/Blog 4.jpg";

export const BlogCardData = [
  {
    id: "0",
    title: "Eat Healthy - Live Healthier",
    discription:
      "Discover the transformative power of healthy eating and its impact on your overall well-being.",
    image: blog1,
    BlogBadgeData: [
      {
        id: "1",
        text: "#HealthyEating",
        textColor: "#05603A",
        textBackColor: "#EEFFF4",
        radius: "6px",
      },

      {
        id: "2",
        text: "#WellnessJourney",
        textColor: "#4A1FB8",
        textBackColor: "rgba(74, 31, 184, 0.07)",
        radius: "6px",
      },

      {
        id: "3",
        text: "#NutritionTips",
        textColor: "#9E165F",
        textBackColor: "rgba(158, 22, 95, 0.04)",
        radius: "6px",
      },

      {
        id: "4",
        text: "#EatWellLiveWell",
        textColor: "#93370D",
        textBackColor: "#FEF0C74F",
        radius: "6px",
      },

      {
        id: "5",
        text: "#ShareMyDine",
        textColor: "#2E4867",
        textBackColor: "#ADB5C22B",
        radius: "6px",
      },
    ],
    details:
      "In today s fast-paced world, where convenience often takes precedence over nutrition, prioritizing healthy eating can seem like an uphill battle. However, the benefits of adopting a balanced and nutritious diet extend far beyond just physical health. ",
    deatils1:
      "Eating healthy can boost your energy levels, improve your mood, enhance your mental clarity, and even reduce your risk of chronic diseases such as heart disease, diabetes, and certain cancers.",
    details2:
      "But what exactly does it mean to eat healthy? At its core, a healthy diet is one that is rich in whole, unprocessed foods such as fruits, vegetables, whole grains, lean proteins, and healthy fats. It s about nourishing your body with the nutrients it needs to thrive, while also enjoying the foods you love in moderation.",
    details3:
      "Making the transition to a healthier diet doesn t have to be daunting. Start by incorporating more plant-based foods into your meals, such as leafy greens, berries, nuts, and legumes. Experiment with different cooking methods and flavor combinations to keep things interesting and satisfying. And remember, small changes add up over time, so focus on progress, not perfection.",
    details4:
      "By prioritizing healthy eating, you re not only investing in your physical health but also laying the foundation for a happier, more vibrant life. So why wait? Start nourishing your body from the inside out and reap the countless benefits of a healthy diet today.",
    Author: "Sarah Johnson",
  },

  {
    id: "1",
    title: "Food is a Symbol of love",
    discription:
      "Explore the emotional and cultural significance of food as a symbol of love and connection.",
    image: blog2,
    BlogBadgeData: [
      {
        id: "1",
        text: "#FoodAndLove",
        textColor: "#05603A",
        textBackColor: "#EEFFF4",
        radius: "6px",
      },

      {
        id: "2",
        text: "#CulinaryConversations",
        textColor: "#4A1FB8",
        textBackColor: "rgba(74, 31, 184, 0.07)",
        radius: "6px",
      },

      {
        id: "3",
        text: "#FoodMemories",
        textColor: "#9E165F",
        textBackColor: "rgba(158, 22, 95, 0.04)",
        radius: "6px",
      },

      {
        id: "4",
        text: "#ShareMyDine",
        textColor: "#93370D",
        textBackColor: "#FEF0C74F",
        radius: "6px",
      },

      {
        id: "5",
        text: "#LoveInTheKitchen",
        textColor: "#2E4867",
        textBackColor: "#ADB5C22B",
        radius: "6px",
      },
    ],
    details:
      "Food has a remarkable ability to transcend its physical form and evoke deep emotions within us. Whether it's a home-cooked meal prepared by a loved one or a special dish shared with friends and family during celebrations, food has long been recognized as a powerful symbol of love and connection. ",
    deatils1:
      "In this blog, we'll delve into the emotional and cultural significance of food, explore the rituals and traditions surrounding food as a symbol of love, and reflect on the memories and moments that make sharing a meal with loved ones truly special.",
    details2:
      "Across cultures and generations, food plays a central role in fostering bonds and strengthening relationships. From the simple act of preparing a meal together to the elaborate feasts served during weddings and holidays, food serves as a tangible expression of love, care, and hospitality. It's no wonder that some of our fondest memories are often centered around the dinner table, where we come together to nourish not only our bodies but also our souls.",
    details3:
      "But food is more than just sustenance; it's a language of its own, capable of conveying love, gratitude, and affection in ways that words sometimes cannot. Whether it's a homemade birthday cake baked with love, a comforting bowl of soup shared during difficult times, or a lavish spread prepared to celebrate life's milestones, each meal tells a story and leaves an indelible mark on our hearts.",
    details4:
      "So the next time you sit down to enjoy a meal with loved ones, take a moment to savor not just the flavors on your plate but also the love and connection that food represents. For in every bite, there is a reminder of the bonds that unite us and the love that sustains us.",

    Author: "Emma Brown",
  },

  {
    id: "2",
    title: "Lets Talk about food",
    discription:
      "Join the conversation as we explore the joys, challenges, and cultural significance of food.",
    image: blog3,
    BlogBadgeData: [
      {
        id: "1",
        text: "#FoodTalks",
        textColor: "#05603A",
        textBackColor: "#EEFFF4",
        radius: "6px",
      },

      {
        id: "2",
        text: "#CulinaryConversations",
        textColor: "#4A1FB8",
        textBackColor: "rgba(74, 31, 184, 0.07)",
        radius: "6px",
      },

      {
        id: "3",
        text: "#FoodCulture",
        textColor: "#9E165F",
        textBackColor: "rgba(158, 22, 95, 0.04)",
        radius: "6px",
      },

      {
        id: "4",
        text: "#ShareMyDine",
        textColor: "#93370D",
        textBackColor: "#FEF0C74F",
        radius: "6px",
      },

      {
        id: "5",
        text: "#FoodiesUnite",
        textColor: "#2E4867",
        textBackColor: "#ADB5C22B",
        radius: "6px",
      },
    ],
    details:
      "Food is a universal language that speaks to our senses, emotions, and memories. It has the power to bring people together, spark conversations, and ignite passions. ",
    deatils1:
      "In this blog, we invite you to join us as we embark on a journey to explore the multifaceted world of food – from its culinary delights to its cultural significance, from its role in shaping identity to its impact on our health and well-being.",
    details2:
      "At Share My Dine, we believe that food is more than just sustenance; it's a reflection of who we are and where we come from. Through the dishes we prepare and the meals we share, we express our creativity, our heritage, and our values. Food has the power to transport us to distant lands, connect us with our roots, and foster a sense of belonging in an increasingly interconnected world.",
    details3:
      "But food is also a topic of much debate and discussion. From the latest food trends to dietary controversies, from the ethics of food production to the joys of home cooking, there's no shortage of topics to explore and opinions to consider. So let's pull up a chair, grab a fork, and dive into the delicious world of food – one conversation at a time.",
    details4:
      "Join us as we celebrate the diversity of food, share our favorite recipes and culinary experiences, and engage in meaningful dialogue about the role of food in our lives. Together, let's explore the rich tapestry of flavors, traditions, and stories that make food one of life's greatest pleasures.",
    Author: "Liam Wilson",
  },

  {
    id: "3",
    title: "Spices and Seasonings",
    discription:
      "Discover the vibrant world of spices and seasonings and their essential role in elevating dishes to new heights of flavor.",
    image: blog4,
    BlogBadgeData: [
      {
        id: "1",
        text: "#SpiceUpYourLife",
        textColor: "#05603A",
        textBackColor: "#EEFFF4",
        radius: "6px",
      },

      {
        id: "2",
        text: "#FlavorJourney ",
        textColor: "#4A1FB8",
        textBackColor: "rgba(74, 31, 184, 0.07)",
        radius: "6px",
      },

      {
        id: "3",
        text: "#GlobalCuisine",
        textColor: "#9E165F",
        textBackColor: "rgba(158, 22, 95, 0.04)",
        radius: "6px",
      },

      {
        id: "4",
        text: "#ShareMyDine",
        textColor: "#93370D",
        textBackColor: "#FEF0C74F",
        radius: "6px",
      },

      {
        id: "5",
        text: "#CulinaryAdventure",
        textColor: "#2E4867",
        textBackColor: "#ADB5C22B",
        radius: "6px",
      },
    ],
    details:
      "Spices and seasonings are the unsung heroes of the culinary world, adding depth, complexity, and character to dishes in ways that transform ordinary meals into extraordinary culinary experiences. From the fiery heat of chili peppers to the aromatic fragrance of cinnamon, spices tantalize our taste buds and awaken our senses, turning each bite into a symphony of flavors.",
    deatils1:
      "At Share My Dine, we understand the importance of spices and seasonings in creating memorable dining experiences. That's why we're dedicated to celebrating the diverse array of flavors found in cuisines from around the world, from the bold and spicy curries of India to the fragrant herb-infused dishes of the Mediterranean.",
    details2:
      "But spices are more than just flavor enhancers; they're also steeped in history, culture, and tradition. For centuries, spices have been prized for their medicinal properties, traded along ancient spice routes, and used in religious ceremonies and cultural rituals. Today, they continue to play a central role in culinary traditions around the globe, connecting us to our past and enriching our present.",
    details3:
      "So whether you're a seasoned chef or a novice cook, don't underestimate the power of spices and seasonings to elevate your cooking to new heights. Experiment with different flavor combinations, embrace the bold and the exotic, and let your taste buds be your guide as you embark on a culinary journey through the vibrant world of spices.",
    Author: "Oliver Harris",
  },
];

export const BlogBadgeData = [
  {
    id: "1",
    text: "#SpiceUpYourLife",
    textColor: "#05603A",
    textBackColor: "#EEFFF4",
    radius: "6px",
  },

  {
    id: "2",
    text: "#FlavorJourney ",
    textColor: "#4A1FB8",
    textBackColor: "rgba(74, 31, 184, 0.07)",
    radius: "6px",
  },

  {
    id: "3",
    text: "#GlobalCuisine",
    textColor: "#9E165F",
    textBackColor: "rgba(158, 22, 95, 0.04)",
    radius: "6px",
  },

  {
    id: "4",
    text: "#ShareMyDine",
    textColor: "#93370D",
    textBackColor: "#FEF0C74F",
    radius: "6px",
  },

  {
    id: "5",
    text: "#CulinaryAdventure",
    textColor: "#2E4867",
    textBackColor: "#ADB5C22B",
    radius: "6px",
  },
];
