import React from "react";
import LoginBody from "../features/login/body/LoginBody";
// import LoginFooter from "../features/login/footer/LoginFooter";

const Login = () => {
  return (
    <div>
      {" "}
      <LoginBody />
    </div>
  );
};

export default Login;
