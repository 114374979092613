import chef1 from "../../../../assets/images/chef/chefCard/Prinka Patel.jpg";
import chef2 from "../../../../assets/images/chef/chefCard/Robert Johnson.jpg";
import chef3 from "../../../../assets/images/chef/chefCard/Maria Rodriguez.jpg";
import chef4 from "../../../../assets/images/chef/chefCard/William Smith.jpg";
import chef5 from "../../../../assets/images/chef/chefCard/Ayesha Khan.jpg";
import chef6 from "../../../../assets/images/chef/chefCard/Charlotte Evans.jpg";
import chef7 from "../../../../assets/images/chef/chefCard/Takashi Yamamoto.jpg";
/////////////////////////////Single Chef
import nancy1 from "../../../../assets/images/chef/chefCard/1.jpeg";
import nancy5 from "../../../../assets/images/chef/chefCard/5.jpeg";
import nancy3 from "../../../../assets/images/chef/chefCard/3.jpeg";
import nancy4 from "../../../../assets/images/chef/chefCard/4.jpeg";
import nancy2 from "../../../../assets/images/chef/chefCard/2.jpeg";
import nancy6 from "../../../../assets/images/chef/chefCard/6.jpeg";
import nancy7 from "../../../../assets/images/chef/chefCard/7.jpeg";

import albert1 from "../../../../assets/images/chef/chefCard/albertIMG/albert1.png";
import albert2 from "../../../../assets/images/chef/chefCard/albertIMG/2.png";
import albert3 from "../../../../assets/images/chef/chefCard/albertIMG/3.png";
import albert4 from "../../../../assets/images/chef/chefCard/albertIMG/4.png";
import albert5 from "../../../../assets/images/chef/chefCard/albertIMG/5.png";

import allessio1 from "../../../../assets/images/chef/chefCard/allessioIMG/allessio1.png";
import allessio2 from "../../../../assets/images/chef/chefCard/allessioIMG/2.png";
import allessio3 from "../../../../assets/images/chef/chefCard/allessioIMG/3.png";
import allessio4 from "../../../../assets/images/chef/chefCard/allessioIMG/4.png";
import allessio5 from "../../../../assets/images/chef/chefCard/allessioIMG/5.png";

import prinak1 from "../../../../assets/images/chef/chefCard/PrinakIMG/1.png";
import prinak2 from "../../../../assets/images/chef/chefCard/PrinakIMG/2.png";
import prinak3 from "../../../../assets/images/chef/chefCard/PrinakIMG/3.png";
import prinak4 from "../../../../assets/images/chef/chefCard/PrinakIMG/4.png";
import prinak5 from "../../../../assets/images/chef/chefCard/PrinakIMG/5.png";

import oursquad1 from "../../../../assets/our-squad/listing/1.png";
import oursquad2 from "../../../../assets/our-squad/listing/2.png";
import oursquad3 from "../../../../assets/our-squad/listing/3.png";
import oursquad4 from "../../../../assets/our-squad/listing/4.png";
import oursquad5 from "../../../../assets/our-squad/listing/5.png";

export const ChefCardData = [
  {
    id: 1,
    title: "Prinka Patel",
    subTitle: "Chennai, India",
    discription: "Master of authentic South Indian flavors.",
    image: nancy1,
    price: "From £65pp / £325 min spend",
    textPosition: "center",
    about: `Chef Prinka Patel brings the rich culinary traditions of South India to life. With extensive experience in creating both traditional and modern vegetarian dishes, Prinka's expertise in using fresh, organic ingredients ensures a healthy and delectable dining experience. Her commitment to authenticity makes her dishes a true taste of Chennai.`,
    from: "true",
    profileimage: chef1,

    Dietaries: [
      {
        id: "1",
        text: "Halal",
        textColor: "#1E293B",
        textBackColor: "#EEFEFF",
        radius: "20px",
      },
      {
        id: "2",
        text: "Vegetarian",
        textColor: "#1E293B",
        textBackColor: "#F2ECFF",
        radius: "20px",
      },
      {
        id: "3",
        text: "Vegan",
        textColor: "#1E293B",
        textBackColor: "#E9FFE1",
        radius: "20px",
      },
      {
        id: "4",
        text: "Gluten free",
        textColor: "#1E293B",
        textBackColor: "#FFFACE",
        radius: "20px",
      },
      {
        id: "5",
        text: "Dairy-Free",
        textColor: "#1E293B",
        textBackColor: "#FFECFC",
        radius: "20px",
      },
      {
        id: "6",
        text: "Organic",
        textColor: "#1E293B",
        textBackColor: "#FFF0F2",
        radius: "20px",
      },
      {
        id: "7",
        text: "Low Carb",
        textColor: "#1E293B",
        textBackColor: "#FBF8E2",
        radius: "20px",
      },
    ],

    cuisines: [
      {
        id: "1",
        text: "Traditional South Indian",
        textColor: "#1E293B",
        textBackColor: "#FBF8E2",
        radius: "20px",
      },
      {
        id: "2",
        text: "Vegetarian Indian",
        textColor: "#1E293B",
        textBackColor: "#EEFEFF",
        radius: "20px",
      },
      {
        id: "3",
        text: "Vegan Indian",
        textColor: "#1E293B",
        textBackColor: "#F2ECFF",
        radius: "20px",
      },
      {
        id: "4",
        text: "Gluten-Free Indian",
        textColor: "#1E293B",
        textBackColor: "#E9FFE1",
        radius: "20px",
      },
      {
        id: "5",
        text: "Dairy-Free Indian",
        textColor: "#1E293B",
        textBackColor: "#E9FFE1",
        radius: "20px",
      },
    ],

    album: [
      {
        albumImage: nancy2,
      },
      {
        albumImage: nancy3,
      },
      {
        albumImage: nancy4,
      },
      {
        albumImage: nancy5,
      },
    ],
  },

  {
    id: 2,
    title: "Robert Johnson",
    subTitle: "Paris, France",
    discription: "French culinary tradition at its finest.",
    image: nancy2,
    price: "From £46pp / £325 min spend",
    about: `Chef Robert Johnson, trained in the heart of Paris, blends classic French techniques with contemporary dietary preferences. Formerly a sous-chef at a Michelin-starred restaurant, Robert now runs a home kitchen to share his passion for exquisite French cuisine. His mastery of both vegetarian and seafood dishes, along with his ability to create flavourful gluten-free and dairy-free meals, makes his cuisine a sophisticated and inclusive delight.`,
    profileimage: chef2,
    from: "true",
    Dietaries: [
      {
        id: "1",
        text: "Vegetarian",
        textColor: "#1E293B",
        textBackColor: "#EEFEFF",
        radius: "20px",
      },
      {
        id: "2",
        text: "Pescatarian",
        textColor: "#1E293B",
        textBackColor: "#F2ECFF",
        radius: "20px",
      },
      {
        id: "3",
        text: "Gluten-Free",
        textColor: "#1E293B",
        textBackColor: "#E9FFE1",
        radius: "20px",
      },
      {
        id: "4",
        text: "Dairy-Free",
        textColor: "#1E293B",
        textBackColor: "#FFFACE",
        radius: "20px",
      },
      {
        id: "5",
        text: "Nut-Free",
        textColor: "#1E293B",
        textBackColor: "#F4FFA8",
        radius: "20px",
      },
      {
        id: "6",
        text: "Paleo",
        textColor: "#1E293B",
        textBackColor: "#FFEFE8",
        radius: "20px",
      },
      {
        id: "7",
        text: "Keto",
        textColor: "#1E293B",
        textBackColor: "#ADB5C22B",
        radius: "20px",
      },
    ],

    cuisines: [
      {
        id: "1",
        text: "Classic French",
        textColor: "#1E293B",
        textBackColor: "#FBF8E2",
        radius: "20px",
      },
      {
        id: "2",
        text: "Seafood",
        textColor: "#1E293B",
        textBackColor: "#EEFEFF",
        radius: "20px",
      },
      {
        id: "3",
        text: "Gluten-Free French",
        textColor: "#1E293B",
        textBackColor: "#F2ECFF",
        radius: "20px",
      },
      {
        id: "4",
        text: "Dairy-Free French",
        textColor: "#1E293B",
        textBackColor: "#E9FFE1",
        radius: "20px",
      },
      {
        id: "5",
        text: "Nut-Free",
        textColor: "#1E293B",
        textBackColor: "#E9FFE1",
        radius: "20px",
      },
      {
        id: "6",
        text: "American",
        textColor: "#1E293B",
        textBackColor: "#EEFEFF",
        radius: "20px",
      },
      {
        id: "3",
        text: "Spanish",
        textColor: "#1E293B",
        textBackColor: "#F2ECFF",
        radius: "20px",
      },
    ],

    album: [
      {
        albumImage: albert2,
      },
      {
        albumImage: albert3,
      },
      {
        albumImage: albert4,
      },
      {
        albumImage: albert5,
      },
    ],
  },

  {
    id: 3,
    title: "Maria Rodriguez",
    subTitle: "Mexico City, Mexico",
    discription: "Vibrant Mexican flavors with traditional flair.",
    image: nancy3,
    price: "From £85pp / £325 min spend",
    about: `Chef Maria Rodriguez is a connoisseur of authentic Mexican cuisine. With her deep knowledge of traditional recipes and modern dietary adaptations, Maria's dishes are a vibrant celebration of Mexican flavours. Her commitment to using fresh, local ingredients ensures every meal is a fiesta of taste and culture.`,
    from: "true",
    profileimage: chef3,

    Dietaries: [
      {
        id: "1",
        text: "Halal",
        textColor: "#1E293B",
        textBackColor: "#EEFEFF",
        radius: "20px",
      },
      {
        id: "2",
        text: "Vegetarian",
        textColor: "#1E293B",
        textBackColor: "#F2ECFF",
        radius: "20px",
      },
      {
        id: "3",
        text: "Vegan",
        textColor: "#1E293B",
        textBackColor: "#E9FFE1",
        radius: "20px",
      },
      {
        id: "4",
        text: "Gluten free",
        textColor: "#1E293B",
        textBackColor: "#FFFACE",
        radius: "20px",
      },
      {
        id: "5",
        text: "Dairy free",
        textColor: "#1E293B",
        textBackColor: "#FFECFC",
        radius: "20px",
      },
      {
        id: "6",
        text: "Paleo",
        textColor: "#1E293B",
        textBackColor: "#FFF0F2",
        radius: "20px",
      },
      {
        id: "7",
        text: "Low FODMAP",
        textColor: "#1E293B",
        textBackColor: "#FBF8E2",
        radius: "20px",
      },
    ],

    cuisines: [
      {
        id: "1",
        text: "Authentic Mexican",
        textColor: "#1E293B",
        textBackColor: "#FBF8E2",
        radius: "20px",
      },
      {
        id: "2",
        text: "Vegetarian Mexican",
        textColor: "#1E293B",
        textBackColor: "#EEFEFF",
        radius: "20px",
      },
      {
        id: "3",
        text: "Vegan Mexican",
        textColor: "#1E293B",
        textBackColor: "#F2ECFF",
        radius: "20px",
      },
      {
        id: "4",
        text: "Gluten-Free",
        textColor: "#1E293B",
        textBackColor: "#E9FFE1",
        radius: "20px",
      },
      {
        id: "5",
        text: "Mexican",
        textColor: "#1E293B",
        textBackColor: "#F2ECFF",
        radius: "20px",
      },
      {
        id: "6",
        text: "Dairy-Free Mexican",
        textColor: "#1E293B",
        textBackColor: "#FFF0F2",
        radius: "20px",
      },
      // {
      //   id: "7",
      //   text: "Mediterranean",
      //   textColor: "#1E293B",
      //   textBackColor: "#FFFAF1",
      //   radius: "20px",
      // },
      // {
      //   id: "8",
      //   text: "British",
      //   textColor: "#1E293B",
      //   textBackColor: "#ECFDFB",
      //   radius: "20px",
      // },
      // {
      //   id: "9",
      //   text: "Gluten free",
      //   textColor: "#1E293B",
      //   textBackColor: "#FFECFC",
      //   radius: "20px",
      // },
    ],

    album: [
      {
        albumImage: allessio2,
      },
      {
        albumImage: allessio3,
      },
      {
        albumImage: allessio4,
      },
      {
        albumImage: allessio5,
      },
    ],
  },

  {
    id: 4,
    title: "William Smith",
    subTitle: "London, United Kingdom",
    discription: "Innovative twists on classic British dishes.",
    image: nancy4,
    price: "From £96pp / £325 min spend",
    about: `Chef William Smith is a pioneer of modern British cuisine. His innovative approach to vegetarian and vegan dishes, along with his expertise in gluten-free and dairy-free cooking, offers a fresh take on British classics. William's culinary creations are both comforting and health-conscious, perfect for contemporary palates.`,
    profileimage: chef4,
    from: "true",
    Dietaries: [
      {
        id: "1",
        text: "Vegetarian, Vegan",
        textColor: "#1E293B",
        textBackColor: "#EEFEFF",
        radius: "20px",
      },
      {
        id: "2",
        text: "Gluten-Free",
        textColor: "#1E293B",
        textBackColor: "#F2ECFF",
        radius: "20px",
      },
      {
        id: "5",
        text: "Dairy-Free",
        textColor: "#1E293B",
        textBackColor: "#F4FFA8",
        radius: "20px",
      },
      {
        id: "6",
        text: "Nut-Free,",
        textColor: "#1E293B",
        textBackColor: "#FFEFE8",
        radius: "20px",
      },
      {
        id: "7",
        text: "Keto",
        textColor: "#1E293B",
        textBackColor: "#ADB5C22B",
        radius: "20px",
      },
      {
        id: "8",
        text: "Low FODMAP",
        textColor: "#1E293B",
        textBackColor: "#F2ECFF",
        radius: "20px",
      },
    ],

    cuisines: [
      {
        id: "1",
        text: "Modern British",
        textColor: "#1E293B",
        textBackColor: "#FBF8E2",
        radius: "20px",
      },
      {
        id: "2",
        text: "Vegetarian British",
        textColor: "#1E293B",
        textBackColor: "#EEFEFF",
        radius: "20px",
      },
      {
        id: "3",
        text: "Vegan British",
        textColor: "#1E293B",
        textBackColor: "#F2ECFF",
        radius: "20px",
      },
      {
        id: "4",
        text: "Gluten-Free British",
        textColor: "#1E293B",
        textBackColor: "#FFF0F2",
        radius: "20px",
      },
      {
        id: "5",
        text: "Dairy-Free British",
        textColor: "#1E293B",
        textBackColor: "#FFFAF1",
        radius: "20px",
      },
      // {
      //   id: "6",
      //   text: "Middle Eastern",
      //   textColor: "#1E293B",
      //   textBackColor: "#ECFDFB",
      //   radius: "20px",
      // },
    ],

    album: [
      {
        albumImage: prinak2,
      },
      {
        albumImage: prinak3,
      },
      {
        albumImage: prinak4,
      },
      {
        albumImage: prinak5,
      },
    ],
  },

  {
    id: 5,
    title: "Ayesha Khan",
    subTitle: "Lahore, Pakistan",
    discription: "Authentic Pakistani flavors served with passion.",
    image: nancy5,
    price: "From £46pp / £325 min spend",
    about: `Chef Ayesha Khan, with her roots in Lahore, excels in crafting traditional Pakistani dishes with a modern twist. Her expertise in a variety of dietary preferences, including halal, vegan, and paleo, ensures that her meals are both authentic and versatile. Ayesha, formerly a chef at a renowned restaurant, now passionately runs her home kitchen to bring the flavours of Pakistan to your home. `,

    profileimage: chef5,
    from: "true",
    Dietaries: [
      {
        id: "1",
        text: "Halal",
        textColor: "#1E293B",
        textBackColor: "#EEFEFF",
        radius: "20px",
      },
      {
        id: "2",
        text: "Vegetarian",
        textColor: "#1E293B",
        textBackColor: "#F2ECFF",
        radius: "20px",
      },
      {
        id: "3",
        text: "Vegan",
        textColor: "#1E293B",
        textBackColor: "#E9FFE1",
        radius: "20px",
      },
      {
        id: "4",
        text: "Gluten free",
        textColor: "#1E293B",
        textBackColor: "#FFFACE",
        radius: "20px",
      },
      {
        id: "5",
        text: "Dairy-Free",
        textColor: "#1E293B",
        textBackColor: "#F4FFA8",
        radius: "20px",
      },
      {
        id: "6",
        text: "Low Carb",
        textColor: "#1E293B",
        textBackColor: "#FFEFE8",
        radius: "20px",
      },
      {
        id: "7",
        text: "Paleo",
        textColor: "#1E293B",
        textBackColor: "#ADB5C22B",
        radius: "20px",
      },
      // {
      //   id: "8",
      //   text: "Egg-Free,",
      //   textColor: "#1E293B",
      //   textBackColor: "#FFEFE8",
      //   radius: "20px",
      // },
      // {
      //   id: "7",
      //   text: "Low-Sodium",
      //   textColor: "#1E293B",
      //   textBackColor: "#ADB5C22B",
      //   radius: "20px",
      // },
    ],

    cuisines: [
      {
        id: "1",
        text: "Traditional Pakistani",
        textColor: "#1E293B",
        textBackColor: "#FBF8E2",
        radius: "20px",
      },
      {
        id: "2",
        text: "Vegetarian Pakistani",
        textColor: "#1E293B",
        textBackColor: "#EEFEFF",
        radius: "20px",
      },
      {
        id: "3",
        text: "Vegan Pakistani",
        textColor: "#1E293B",
        textBackColor: "#F2ECFF",
        radius: "20px",
      },
      {
        id: "4",
        text: "Gluten-Free Pakistani",
        textColor: "#1E293B",
        textBackColor: "#E9FFE1",
        radius: "20px",
      },
      {
        id: "6",
        text: "Dairy-Free Pakistani",
        textColor: "#1E293B",
        textBackColor: "#FFEFE8",
        radius: "20px",
      },
    ],

    album: [
      {
        albumImage: albert2,
      },
      {
        albumImage: albert3,
      },
      {
        albumImage: albert4,
      },
      {
        albumImage: albert5,
      },
    ],
  },

  {
    id: 6,
    title: "Charlotte Evans",
    subTitle: "Brighton, United Kingdom",
    discription: "Creative plant-based dishes bursting with flavor.",
    image: nancy6,
    price: "From £65pp / £325 min spend",
    about: `Chef Charlotte Evans is dedicated to creating innovative plant-based cuisine. Specialising in vegan comfort food and gluten-free dishes, Charlotte's culinary style is both hearty and healthful. Her emphasis on raw, plant-based ingredients makes her dishes a nourishing and delicious choice for any meal.`,
    from: "true",
    profileimage: chef6,

    Dietaries: [
      {
        id: "1",
        text: "Vegetarian",
        textColor: "#1E293B",
        textBackColor: "#EEFEFF",
        radius: "20px",
      },
      {
        id: "2",
        text: "Vegan",
        textColor: "#1E293B",
        textBackColor: "#F2ECFF",
        radius: "20px",
      },
      {
        id: "3",
        text: "Gluten-Free",
        textColor: "#1E293B",
        textBackColor: "#E9FFE1",
        radius: "20px",
      },
      {
        id: "4",
        text: "Dairy-Free",
        textColor: "#1E293B",
        textBackColor: "#FFFACE",
        radius: "20px",
      },
      {
        id: "5",
        text: "Nut-Free",
        textColor: "#1E293B",
        textBackColor: "#FFECFC",
        radius: "20px",
      },
      {
        id: "6",
        text: "Plant-Based",
        textColor: "#1E293B",
        textBackColor: "#FFF0F2",
        radius: "20px",
      },
      {
        id: "2",
        text: "Raw",
        textColor: "#1E293B",
        textBackColor: "#F2ECFF",
        radius: "20px",
      },
    ],

    cuisines: [
      {
        id: "1",
        text: "Plant-Based",
        textColor: "#1E293B",
        textBackColor: "#FBF8E2",
        radius: "20px",
      },
      {
        id: "2",
        text: "Vegan Comfort Food",
        textColor: "#1E293B",
        textBackColor: "#EEFEFF",
        radius: "20px",
      },
      {
        id: "3",
        text: "Gluten-Free Vegan",
        textColor: "#1E293B",
        textBackColor: "#F2ECFF",
        radius: "20px",
      },
      {
        id: "4",
        text: "Dairy-Free Vegan",
        textColor: "#1E293B",
        textBackColor: "#E9FFE1",
        radius: "20px",
      },
      {
        id: "1",
        text: "Nut-Free Vegan",
        textColor: "#1E293B",
        textBackColor: "#FBF8E2",
        radius: "20px",
      },
    ],

    album: [
      {
        albumImage: nancy2,
      },
      {
        albumImage: nancy3,
      },
      {
        albumImage: nancy4,
      },
      {
        albumImage: nancy5,
      },
    ],
  },

  {
    id: 7,
    title: "Takashi Yamamoto",
    subTitle: "Tokyo, Japan",
    discription: "Expert in traditional Japanese culinary arts.",
    image: nancy7,
    price: "From £85pp / £325 min spend",
    about: `Chef Takashi Yamamoto brings the essence of traditional Japanese cuisine to your table. Having worked at a Michelin-starred restaurant, Takashi decided to run a home kitchen to share his passion for authentic Japanese food. With a focus on fresh seafood and meticulous preparation, Takashi's dishes are a delicate balance of flavour and health. His ability to cater to various dietary needs, including gluten-free and nut-free, ensures a delightful and inclusive dining experience.`,
    profileimage: chef7,
    from: "true",
    Dietaries: [
      {
        id: "1",
        text: "Vegetarian",
        textColor: "#1E293B",
        textBackColor: "#EEFEFF",
        radius: "20px",
      },
      {
        id: "2",
        text: "Pescatarian",
        textColor: "#1E293B",
        textBackColor: "#F2ECFF",
        radius: "20px",
      },
      {
        id: "3",
        text: "Gluten-Free",
        textColor: "#1E293B",
        textBackColor: "#E9FFE1",
        radius: "20px",
      },
      {
        id: "4",
        text: "Dairy-Free",
        textColor: "#1E293B",
        textBackColor: "#FFFACE",
        radius: "20px",
      },
      {
        id: "5",
        text: "Nut-Free",
        textColor: "#1E293B",
        textBackColor: "#F2ECFF",
        radius: "20px",
      },
      {
        id: "6",
        text: "Low Carb",
        textColor: "#1E293B",
        textBackColor: "#FFF0F2",
        radius: "20px",
      },
      // {
      //   id: "7",
      //   text: "Low FODMAP",
      //   textColor: "#1E293B",
      //   textBackColor: "#FFFAF1",
      //   radius: "20px",
      // },
    ],

    cuisines: [
      {
        id: "1",
        text: "Traditional Japanese",
        textColor: "#1E293B",
        textBackColor: "#FBF8E2",
        radius: "20px",
      },
      {
        id: "2",
        text: "Seafood",
        textColor: "#1E293B",
        textBackColor: "#EEFEFF",
        radius: "20px",
      },
      {
        id: "3",
        text: "Gluten-Free Japanese",
        textColor: "#1E293B",
        textBackColor: "#F2ECFF",
        radius: "20px",
      },
      {
        id: "4",
        text: "Dairy-Free Japanese",
        textColor: "#1E293B",
        textBackColor: "#E9FFE1",
        radius: "20px",
      },
      // {
      //   id: "5",
      //   text: "Nut-Free Japanese",
      //   textColor: "#1E293B",
      //   textBackColor: "#F2ECFF",
      //   radius: "20px",
      // },
      // {
      //   id: "6",
      //   text: "French",
      //   textColor: "#1E293B",
      //   textBackColor: "#FFF0F2",
      //   radius: "20px",
      // },
      // {
      //   id: "7",
      //   text: "Mediterranean",
      //   textColor: "#1E293B",
      //   textBackColor: "#FFFAF1",
      //   radius: "20px",
      // },
      // {
      //   id: "8",
      //   text: "British",
      //   textColor: "#1E293B",
      //   textBackColor: "#ECFDFB",
      //   radius: "20px",
      // },
      // {
      //   id: "9",
      //   text: "Gluten free",
      //   textColor: "#1E293B",
      //   textBackColor: "#FFECFC",
      //   radius: "20px",
      // },
    ],

    album: [
      {
        albumImage: allessio2,
      },
      {
        albumImage: allessio3,
      },
      {
        albumImage: allessio4,
      },
      {
        albumImage: allessio5,
      },
    ],
  },
];

export const ourSquadData = [
  {
    id: 1,
    title: "John",
    subTitle: "Croydon",
    discription: "Product Manager",
    image: oursquad1,
    textPosition: "center",
  },
  {
    id: 2,
    title: "Joseph",
    subTitle: "Croydon",
    discription: "Designer",
    image: oursquad2,
    textPosition: "center",
  },
  {
    id: 3,
    title: "Emma",
    subTitle: "Croydon",
    discription: "Data Analyst",
    image: oursquad3,
    textPosition: "center",
  },
  {
    id: 4,
    title: "Henry",
    subTitle: "Croydon",
    discription: "Product Owner",
    image: oursquad4,
    textPosition: "center",
  },
  {
    id: 5,
    title: "Samuel",
    subTitle: "Croydon",
    discription: "Marketing Manager",
    image: oursquad5,
    textPosition: "center",
  },
  {
    id: 6,
    title: "Emma",
    subTitle: "Croydon",
    discription: "Data Analyst",
    image: oursquad3,
    textPosition: "center",
  },
  {
    id: 7,
    title: "Henry",
    subTitle: "Croydon",
    discription: "Product Owner",
    image: oursquad4,
    textPosition: "center",
  },
  {
    id: 8,
    title: "John",
    subTitle: "Croydon",
    discription: "Product Manager",
    image: oursquad1,
    textPosition: "center",
  },
  {
    id: 9,
    title: "Joseph",
    subTitle: "Croydon",
    discription: "Designer",
    image: oursquad2,
    textPosition: "center",
  },
];
