import Eat1 from "../../../assets/images/eat-healthy/1.png";
import Eat2 from "../../../assets/images/eat-healthy/2.jpg";
import Eat3 from "../../../assets/images/eat-healthy/3.jpg";
import Eat4 from "../../../assets/images/eat-healthy/4.png";
export const recipes = [
  {
    id: "0",
    title: "Eat Healthy",
    subtitle:
      "Eating healthy is an important part of maintaining good physical and mental health.",
    description: "High Protein Bowl Recipe",
    cardName: "in Eat healthy",
    ingredients: [
      "1 cup cooked quinoa",
      "1 cup cooked chicken breast, diced (substitute with tofu for a vegetarian option)",
      "1/2 cup cooked black beans, drained and rinsed",
      "1/2 cup steamed broccoli florets",
      "1/2 cup diced cucumber",
      "1/4 cup diced red bell pepper",
      "1/4 cup diced red onion",
      "1/4 cup cherry tomatoes, halved",
      "1/4 cup sliced avocado",
      "2 tablespoons chopped fresh cilantro",
      "2 tablespoons olive oil",
      "2 tablespoons lemon juice",
      "1 teaspoon Dijon mustard",
      "Salt and pepper to taste",
    ],
    img: Eat1,
    allergens: [
      "This recipe contains chicken, but it can be easily substituted with tofu for a dairy-free, nut-free, and gluten-free option",
      "Check ingredient labels for potential allergens in condiments like Dijon mustard",
    ],
    dietaryInfo: [
      "High in protein",
      "Gluten-free (when using gluten-free Dijon mustard)",
      "Dairy-free (when using tofu)",
      "Nut-free",
    ],
    cuisine: ["This recipe is inspired by Mediterranean cuisine"],
    spiceLevel: [
      "Mild (adjust to your preference with hot sauce or chili flakes)",
    ],
    nutrition: {
      calories: "450-450 kcal",
      protein: "30g (varies with chicken or tofu)",
      carbohydrates: "35gg",
      fiber: "10g",
      fat: "18g",
      vitamin: "80% of daily recommended intake",
      iron: "20% of daily recommended intake",
      potassium: "25% of daily recommended intake",
    },
    instructions: [
      {
        step: 1,
        description: "Prepare the Dressing:",
        subSteps: [
          " a. In a small bowl, whisk together the olive oil, lemon juice, Dijon mustard, salt, and pepper until well combined. Set aside.",
        ],
      },
      {
        step: 2,
        description: "Assemble the Bowl:",
        subSteps: [
          "a. In a large bowl, start by layering the cooked quinoa as the base.",
          "b. Arrange the diced chicken (or tofu) on top of the quinoa.",
          "c. Add the black beans, steamed broccoli, diced cucumber, red bell pepper, red onion, cherry tomatoes, and sliced avocado to the bowl.",
        ],
      },
      {
        step: 3,
        description: "Drizzle with Dressing:",
        subSteps: [
          "a. Drizzle the prepared dressing over the ingredients in the bowl.",
        ],
      },
      {
        step: 4,
        description: "Garnish and Serve",
        subSteps: [
          "a. Sprinkle chopped cilantro on top for garnish.",
          "b. Serve immediately, or refrigerate for later use.",
        ],
      },
    ],
    GlycemicIndex: {
      Score: "49",
      Level: "Low",
    },
    GlycemicLoad: {
      Score: "10",
      Level: "Moderate",
    },
    HealthScore: "9.1/10",
  },
  {
    id: "1",
    title: "Weekday Dishes",
    subtitle: "Quick, delicious meals to get you through the busy week.",
    description: "Easy Chicken Stir-Fry",
    cardName: "in Weekday Dishes",
    img: Eat2,
    ingredients: [
      "1 lb chicken breast, thinly sliced (substitute with tempeh for a vegetarian option)",
      "2 cups mixed bell peppers, sliced",
      "1 cup snap peas",
      "1 cup broccoli florets",
      "1/2 cup sliced carrots",
      "1/2 cup sliced onions",
      "2 cloves garlic, minced",
      "2 tablespoons soy sauce (use tamari for gluten-free)",
      "1 tablespoon sesame oil",
      "1 tablespoon olive oil",
      "1 teaspoon grated ginger",
      "Salt and pepper to taste",
      "Sesame seeds for garnish",
    ],
    allergens: [
      "Contains soy (substitute with coconut aminos for soy-free)",
      "Check ingredient labels for potential allergens in condiments",
    ],
    dietaryInfo: [
      "High in protein",
      "Dairy-free",
      "Gluten-free (when using tamari)",
      "Nut-free",
    ],
    cuisine: ["Inspired by Asian cuisine"],
    spiceLevel: ["Mild (adjust to your preference with red pepper flakes)"],
    nutrition: {
      calories: "350-400 kcal",
      protein: "30g (varies with chicken or tempeh)",
      carbohydrates: "20g",
      fiber: "6g",
      fat: "15g",
      vitamin: "60% of daily recommended intake",
      iron: "15% of daily recommended intake",
      potassium: "20% of daily recommended intake",
    },
    instructions: [
      {
        step: 1,
        description: "Prepare the Chicken",
        subSteps: [
          "a. Heat olive oil in a large skillet over medium-high heat.",
          "b. Add sliced chicken breast and cook until browned and cooked through. Remove and set aside.",
        ],
      },
      {
        step: 2,
        description: "Cook the Vegetables",
        subSteps: [
          "a. In the same skillet, add sesame oil.",
          "b. Add garlic and ginger, sauté until fragrant.",
          "c. Add bell peppers, snap peas, broccoli, carrots, and onions. Stir-fry for 5-7 minutes until tender-crisp.",
        ],
      },
      {
        step: 3,
        description: "Combine and Season",
        subSteps: [
          "a. Return the chicken to the skillet.",
          "b. Add soy sauce, salt, and pepper. Stir to combine and heat through.",
        ],
      },
      {
        step: 4,
        description: "Garnish and Serve",
        subSteps: [
          "a. Sprinkle with sesame seeds.",
          "b. Serve immediately with rice or noodles, if desired.",
        ],
      },
    ],
    GlycemicIndex: {
      Score: "55",
      Level: "Moderate",
    },
    GlycemicLoad: {
      Score: "12",
      Level: "Moderate",
    },
    HealthScore: "8.5/10",
  },
  {
    id: "2",
    title: "15 Min Meals",
    subtitle: "Whip up tasty dishes in just 15 minutes or less.",
    description: "Quick Avocado Toast",
    cardName: "in Quick Avocado Toast",
    img: Eat3,
    ingredients: [
      "2 slices whole grain bread (substitute with gluten-free bread if needed)",
      "1 ripe avocado",
      "1/4 cup cherry tomatoes, halved",
      "1 tablespoon lemon juice",
      "1 tablespoon olive oil",
      "Salt and pepper to taste",
      "1 teaspoon red pepper flakes",
      "Fresh herbs (optional: cilantro or basil)",
    ],
    allergens: ["Contains wheat (substitute with gluten-free bread if needed)"],
    dietaryInfo: ["High in healthy fats", "Vegan", "Dairy-free", "Nut-free"],
    cuisine: ["Inspired by contemporary cuisine"],
    spiceLevel: [
      "Mild (adjust to your preference with more red pepper flakes)",
    ],
    nutrition: {
      calories: "250-300 kcal",
      protein: "5g",
      carbohydrates: "25g",
      fiber: "8g",
      fat: "18g",
      vitamin: "30% of daily recommended intake",
      iron: "10% of daily recommended intake",
      potassium: "15% of daily recommended intake",
    },
    instructions: [
      {
        step: 1,
        description: "Prepare the Avocado",
        subSteps: [
          "a. In a small bowl, mash the avocado with lemon juice, salt, and pepper.",
        ],
      },
      {
        step: 2,
        description: "Toast the Bread",
        subSteps: ["a. Toast the whole grain bread until golden and crisp."],
      },
      {
        step: 3,
        description: "Assemble the Toast",
        subSteps: [
          "a. Spread the mashed avocado mixture evenly on the toasted bread slices.",
          "b. Top with cherry tomatoes and a drizzle of olive oil.",
        ],
      },
      {
        step: 4,
        description: "Garnish and Serve",
        subSteps: [
          "a. Sprinkle with red pepper flakes and fresh herbs.",
          "b. Serve immediately.",
        ],
      },
    ],
    GlycemicIndex: {
      Score: "45",
      Level: "Low",
    },
    GlycemicLoad: {
      Score: "12",
      Level: "Low",
    },
    HealthScore: "8.5/10",
  },
  {
    id: "3",
    title: "Vegan Mania",
    subtitle: "Discover exciting and flavourful plant-based recipes.",
    description: "Vegan Lentil Curry",
    cardName: "in Vegan Mania",
    img: Eat4,
    ingredients: [
      "1 cup dried lentils, rinsed and drained",
      "1 can coconut milk",
      "2 cups vegetable broth",
      "1 cup diced tomatoes (fresh or canned)",
      "1 cup chopped spinach",
      "1 onion, finely chopped",
      "2 cloves garlic, minced",
      "1 tablespoon curry powder",
      "1 teaspoon ground cumin",
      "1 teaspoon ground turmeric",
      "1 tablespoon olive oil",
      "Salt and pepper to taste",
      "Fresh cilantro for garnish",
    ],
    allergens: [
      "None (always check ingredient labels for potential cross-contamination)",
    ],
    dietaryInfo: [
      "High in fiber",
      "Vegan",
      "Gluten-free",
      "Dairy-free",
      "Nut-free",
    ],
    cuisine: ["Inspired by Indian cuisine"],
    spiceLevel: ["Medium (adjust to your preference with additional spices)"],
    nutrition: {
      calories: "350-400 kcal",
      protein: "15g",
      carbohydrates: "40g",
      fiber: "12g",
      fat: "15g",
      vitamin: "50% of daily recommended intake",
      iron: "25% of daily recommended intake",
      potassium: "20% of daily recommended intake",
    },
    instructions: [
      {
        step: 1,
        description: "Sauté Aromatics",
        subSteps: [
          "a. Heat olive oil in a large pot over medium heat.",
          "b. Add chopped onion and sauté until translucent.",
          "c. Add garlic and spices (curry powder, cumin, turmeric) and cook for another minute until fragrant.",
        ],
      },
      {
        step: 2,
        description: "Cook Lentils",
        subSteps: [
          "a. Add lentils, vegetable broth, and diced tomatoes to the pot.",
          "b. Bring to a boil, then reduce heat and simmer for 20-25 minutes until lentils are tender.",
        ],
      },
      {
        step: 3,
        description: "Add Coconut Milk and Spinach",
        subSteps: [
          "a. Stir in coconut milk and chopped spinach.",
          "b. Simmer for another 5 minutes until spinach is wilted and the curry is heated through.",
        ],
      },
      {
        step: 4,
        description: "Garnish and Serve",
        subSteps: [
          "a. Season with salt and pepper to taste.",
          "b. Garnish with fresh cilantro and serve with rice or naan bread.",
        ],
      },
    ],
    GlycemicIndex: {
      Score: "50",
      Level: "Moderate",
    },
    GlycemicLoad: {
      Score: "9",
      Level: "Low",
    },
    HealthScore: "9.5/10",
  },
];
