import React from "react";
import Badge from "../blog/blogBadge";
import { Box, Container } from "@mui/material";
import dot from "../../assets/svg/Ellipse.svg";

const BlogBadgeData = [
  {
    id: "1",
    textColor: "#05603A",
    text: "Pick a design",
    textBackColor: "#EEFFF4",
    radius: "6px",
  },

  {
    id: "2",
    textColor: "#4A1FB8",
    text: "Personalise it",
    textBackColor: "rgba(74, 31, 184, 0.07)",
    radius: "6px",
  },

  {
    id: "3",
    textColor: "#9E165F",
    text: "Gift it",
    textBackColor: "#9E165F0A",
    radius: "6px",
  },
];

function GiftBadge() {
  return (
    <Container >
      <Box
        sx={{
          maxWidth: "100%",
          width: "100%",
          height: "auto",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          margin: "auto",
        }}
      >
        {BlogBadgeData.map((blog, index) => (
          <>
          
            <Badge blog={blog} key={index} />
            {blog.id === '3' ? 
              console.log('blog', blog)
             : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "15px",
                  marginLeft: "10px",
                  marginBottom: "10px",
                }}
              >
                <img src={dot} alt="Logo" />
              </Box>
            )}
          </>
        ))}
      </Box>
    </Container>
  );
}

export default GiftBadge;
