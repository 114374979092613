import React from "react";
import Badge from "../blog/blogBadge";
// import { BlogBadgeData } from '../blog/blogData'
import { Box, Container } from "@mui/material";

function BlogInnerPageBadge({ BlogBadgeData }) {
  return (
    <Container>
      <Box
        sx={{
          maxWidth: "450px",
          width: "100%",
          height: "100%",
          margin: "auto",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {BlogBadgeData.map((e, index) => (
          <Badge blog={e} key={index} />
        ))}
      </Box>
    </Container>
  );
}

export default BlogInnerPageBadge;
