import Person1 from "../../assets/images/home/person-1.png";
import Person2 from "../../assets/images/home/person-2.png";
import Person3 from "../../assets/images/home/person-3.png";

export const TESTIMONIALS = [
  {
    id: "0",
    img: Person1,
    name: "Ama Ampomah",
    rating: 4,
    review:
      "I recently had a fantastic dining experience with Share My Dine! The host prepared a delicious three-course meal with a personalized touch, and the atmosphere was cozy and welcoming. I enjoyed learning about the host's culinary background and cultural influences while savouring the flavours of the dishes. Highly recommend!",
  },
  {
    id: "1",
    img: Person2,
    name: "Kevin Andrew",
    rating: 5,
    review:
      "I recently dined at an at-home restaurant through Share My Dine, and I was blown away by the healthy and fresh ingredients used in the dishes. The food was not only delicious but also nourishing. I highly recommend this experience to anyone looking for a tasty and healthy meal in a cozy atmosphere.",
  },
  {
    id: "2",
    img: Person3,
    name: "Benjamin Ward ",
    rating: 4,
    review:
      "I had an amazing curry dish from a Share My Dine host. The flavors were bold and the spices were perfectly balanced. The host even shared some cooking tips with us. Overall, it was a fantastic dining experience and I would highly recommend trying it out!",
  },
];
