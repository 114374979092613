import React from "react";
import styles from "./styles.module.scss";
import { Box, Container, Grid } from "@mui/material";
import image1 from "../../../assets/images/our-collection/listing/11.jpg";
import image2 from "../../../assets/images/our-collection/listing/12.jpg";
import image3 from "../../../assets/images/our-collection/listing/13.jpg";
import image4 from "../../../assets/images/our-collection/listing/14.jpg";
// import image5 from "../../../assets/images/our-collection/listing/5.png";
// import image6 from "../../../assets/images/our-collection/listing/6.png";
// import image7 from "../../../assets/images/our-collection/listing/7.png";
// import image8 from "../../../assets/images/our-collection/listing/8.png";
// import image9 from "../../../assets/images/our-collection/listing/9.png";
// import image10 from "../../../assets/images/our-collection/listing/10.png";
import OurCollectionCard from "../../../components/our-collecton-card/OurCollection";

const OurCollectionListing = () => {
  const data = [
    {
      title: `Home Cooked Meals `,
      price: `46`,
      image: image1,
      description: `Delight in the warmth and authenticity of homemade dishes crafted with love by our talented hosts. From traditional family recipes to modern twists on classics, each meal tells a story and invites you to share in the joy of home cooking.`,
      title2: `Turkish Cuisine`,
    },
    {
      title: `Gourmet Experiences`,
      price: `32`,
      image: image2,
      description: `Indulge in gourmet dining experiences that elevate the art of culinary craftsmanship. Immerse yourself in the exquisite flavors, impeccable presentation, and attentive service that define our selection of gourmet meals prepared by culinary experts.`,
      title2: `Northern Muharraq`,
    },
    {
      title: `Themed Dinners`,
      price: `40`,
      image: image3,
      description: `Embark on themed culinary journeys that transport you to distant lands and evoke nostalgic memories. From themed menus inspired by global cuisines to immersive dining experiences centered around cultural celebrations, each themed dinner promises an unforgettable adventure.`,
      title2: `Northern Bahrain`,
    },
    {
      title: `Special Occasions`,
      price: `46`,
      image: image4,
      description: `Celebrate life's milestones and moments with our collection of dining experiences tailored for special occasions. Whether it's a romantic dinner for two, a birthday celebration with friends, or a family gathering to cherish, our hosts are here to make your special moments memorable.`,
      title2: `Chinese cuisine`,
    },
  ];
  return (
    <Box className={styles.parent}>
      <Container>
        <Grid container spacing={3} rowSpacing={19} justifyContent={"center"}>
          {data?.map((item, index) => (
            <Grid
              item
              xs={12}
              md={6}
              lg={6}
              xl={6}
              key={index}
              display={"flex"}
            >
              <OurCollectionCard item={item} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default OurCollectionListing;
