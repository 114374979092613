import {
  Box,
  Button,
  Container,
  Grid,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import React from "react";
import Ingredients from "../../assets/images/eat-healthy/Ingredients.png";
import Allergens from "../../assets/images/eat-healthy/Ingredients.png";
import Dietary from "../../assets/images/eat-healthy/allergens.png";
import Cuisine from "../../assets/images/eat-healthy/cuisine.png";
import NoSpice from "../../assets/images/eat-healthy/spice.png";
import Dot from "../../assets/images/eat-healthy/icon-dot.png";
import ThemeButton from "../../components/theme-button/theme-button";
import Customtextarea from "./CustomTextarea";
import { useLocation } from "react-router-dom";
import { recipes } from "./EatHealtyData";

const Card1 = (EatHealthy) => {
  return [
    {
      id: "1",
      img: Ingredients,
      heading: "Ingredients",
      icon: Dot,
      names: EatHealthy?.ingredients,
    },
    {
      id: "2",
      img: Allergens,
      heading: "Allergens",
      icon: Dot,
      names: EatHealthy?.allergens,
    },
    {
      id: "3",
      img: Dietary,
      heading: "Dietary Info",
      icon: Dot,
      names: EatHealthy?.dietaryInfo,
    },
    {
      id: "4",
      img: Cuisine,
      heading: "Cuisine",
      icon: Dot,
      names: EatHealthy?.cuisine,
    },
    {
      id: "5",
      img: NoSpice,
      heading: "Spice Level",
      icon: Dot,
      names: EatHealthy?.spiceLevel,
    },
  ];
};

const EatCard1 = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const EatHealthy = recipes.find((eat) => eat?.id === id);
  // we want to map EatHealthy
  const data = Card1(EatHealthy);
 
  return (
    <>
      <Container>
        <Box>
          <Grid container spacing={5}>
            {data?.map((item) => {
              return (
                <Grid item md={2.4} sm={4} xs={6} key={item.id}>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <img src={item.img} alt={item.heading} />
                      <Typography className="font-weight-600 font-family-Work margin-bottom-1">
                        {item.heading}
                      </Typography>
                    </div>
                    <div>
                      <Typography
                        sx={{
                          borderRight: "1px solid rgba(131, 164, 249, 0.15)",
                          height: "5vh",
                        }}
                      ></Typography>
                    </div>
                  </Box>
                  <Box>
                    {item.names.map((name, index) => (
                      <Typography
                        sx={{ pb: "5px" }}
                        className="font-weight-400 font-family-Work secondary-title"
                        key={index}
                      >
                        <span style={{ marginRight: "5px" }}>
                          <img src={item.icon} alt={`dot-${index}`} />
                        </span>
                        {name}
                      </Typography>
                    ))}
                  </Box>
                </Grid>
              );
            })}
            <Grid item xs={12}>
              <Typography
                sx={{
                  borderBottom: "1px solid rgba(131, 164, 249, 0.15)",
                }}
              ></Typography>
            </Grid>
            .
          </Grid>
        </Box>
        <Box sx={{ pb: "20px" }}>
          <Box>
            <Grid
              container
              justifyContent={"space-between"}
              alignItems={"center"}
              sx={{ mb: "30px" }}
            >
              <Box>
                <Typography
                  sx={{ fontSize: "32px" }}
                  className="font-weight-600 font-family-Work"
                >
                  Instructions
                </Typography>
              </Box>
              <Box sx={{ mt: { sm: "0", xs: "10px" } }}>
                <ThemeButton title="Start Cooking" />
              </Box>
            </Grid>
          </Box>
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{
                background: "#FBFBFB",
                borderRadius: "8px",
                padding: "16px",
                mt: "15px",
              }}
            >
              <Typography
                sx={{ fontSize: "18px", pb: "10px" }}
                className="font-weight-500 font-family-Work"
              >
                Step 1
              </Typography>
              <Typography className="font-weight-400 font-family-Work">
                <span style={{ fontSize: "18px", fontWeight: 500 }}>
                  {EatHealthy?.instructions[0]?.description}
                </span>
                :<br /> {EatHealthy?.instructions[0]?.subSteps[0]}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                background: "#FBFBFB",
                borderRadius: "8px",
                padding: "16px",
                mt: "15px",
              }}
            >
              <Typography
                sx={{ fontSize: "18px", pb: "10px" }}
                className="font-weight-500 font-family-Work"
              >
                Step 2
              </Typography>
              <Typography className="font-weight-400 font-family-Work">
                <span style={{ fontSize: "18px", fontWeight: 500 }}>
                  {EatHealthy?.instructions[1]?.description}
                </span>
                :<br />
                {EatHealthy?.instructions[1]?.subSteps[0]}
                base.
                <br />
                {EatHealthy?.instructions[1]?.subSteps[1]}
                <br />
                {EatHealthy?.instructions[1]?.subSteps[2]}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                background: "#FBFBFB",
                borderRadius: "8px",
                padding: "16px",
                mt: "15px",
              }}
            >
              <Typography
                sx={{ fontSize: "18px", pb: "10px" }}
                className="font-weight-500 font-family-Work"
              >
                Step 3
              </Typography>
              <Typography className="font-weight-400 font-family-Work">
                <span style={{ fontSize: "18px", fontWeight: 500 }}>
                  {EatHealthy?.instructions[2]?.description}
                </span>
                :<br />
                {EatHealthy?.instructions[2]?.subSteps[0]}
                <br />
                {EatHealthy?.instructions[2]?.subSteps[1]}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                background: "#FBFBFB",
                borderRadius: "8px",
                padding: "16px",
                mt: "15px",
              }}
            >
              <Typography
                sx={{ fontSize: "18px", pb: "10px" }}
                className="font-weight-500 font-family-Work"
              >
                Step 4
              </Typography>
              <Typography className="font-weight-400 font-family-Work">
                <span style={{ fontSize: "18px", fontWeight: 500 }}>
                  {EatHealthy?.instructions[3]?.description}
                </span>
                :<br />
                {EatHealthy?.instructions[3]?.subSteps[0]}
                <br />
                {EatHealthy?.instructions[3]?.subSteps[1]}
              </Typography>
            </Grid>
            {/* <Box sx={{ m: "20px" }}>
              <Button
                className="font-family-Work"
                sx={{
                  background: "white",
                  border: "1px solid #292D32",
                  fontWeight: "14px",
                  fontSize: "14px",
                  p: "10px",
                  color: "#292D32",
                }}
              >
                Make it
              </Button>
            </Box> */}
            <Typography
              sx={{
                borderBottom: "1px solid rgba(131, 164, 249, 0.15)",
                width: "100%",
              }}
            ></Typography>
            {/* <Grid
              item
              xs={12}
              sx={{
                background: "#FFF4F6",
                p: "10px",
              }}
            >
              <Typography
                sx={{ fontSize: "18px" }}
                className="font-weight-500 font-family-Work"
              >
                Notes
              </Typography>
              <Typography className="font-weight-400 font-family-Work">
                <ul>
                  <li>
                    Feel free to customize this high-protein bowl with your
                    favorite vegetables or protein sources.
                  </li>
                  <li>
                    You can add a sprinkle of feta cheese or a dollop of Greek
                    yogurt if you're not concerned about dairy and want to boost
                    the protein content.
                  </li>
                  <li>
                    Adjust the dressing ingredients to your taste. You can make
                    it spicier with a dash of hot sauce or add more lemon juice
                    for extra tanginess.
                  </li>
                </ul>
              </Typography>
              <Typography
                sx={{ fontSize: "18px", py: "10px", color: "#FF611D" }}
                className="font-weight-500 font-family-Work"
              >
                Add Note
              </Typography>
              <Box sx={{ pr: "5px" }}>
                <Customtextarea />
              </Box>
            </Grid> */}
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default EatCard1;
