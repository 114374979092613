// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fancy-text .outline-text {
  font: 800 40px Work Sans;
  color: #ffb697;
  letter-spacing: 15px;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px;
}`, "",{"version":3,"sources":["webpack://./src/components/fancy-heading-text/fancy-heading-text.scss"],"names":[],"mappings":"AACI;EACI,wBAAA;EAEA,cAAA;EACA,oBAAA;EACA,oCAAA;EACA,wBAAA;AADR","sourcesContent":[".fancy-text {\n    .outline-text {\n        font: 800 40px Work Sans;\n        // color:#7097C536;\n        color: #ffb697;\n        letter-spacing:15px;\n        -webkit-text-fill-color: transparent;\n        -webkit-text-stroke: 1px;\n      }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
