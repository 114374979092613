import React from "react";
import HeroSectionReusable from "../features/hero-section-reusable/hero-section-reuseable";
// import OurPassionateCheffs from "../components/our-passionate-chef/our-passionate-chef";
import { Box, Container } from "@mui/material";
import Careermain from "../features/career-page/firstSection/FirstSection";

const Careers = () => {
  return (
    <>
      <HeroSectionReusable
        para={
          "We’re always looking for creative, talented self-starters to join the <span class='primary-color font-weight-600 ' > Share My Dine</span> family."
        }
        heading={"Careers"}
        gifTitle={"Welcome"}
      />

      {/* <Careermain /> */}
      <Box height={"100%"} width={"100%"} padding={"40px 0"}>
        <Container>
          <iframe
            src="https://recruiting-stg.personnellibrary.co.uk//job-boards?boardName=newBoard-1716270227336"
            width="100%"
            height="100%"
            no-border
            title="Jobs Widget"
            scrolling="no"
            style={{ border: "0", minHeight: "100vh" }}
          ></iframe>
        </Container>
      </Box>

      {/* <OurPassionateCheffs /> */}
    </>
  );
};

export default Careers;
