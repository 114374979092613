import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";
import { recipes } from "./EatHealtyData";

const Names = [
  "Calories",
  "Protein",
  "Carbohydrates",
  "Fiber",
  "Fat",
  "Vitamin",
  "Iron",
  "Potassium",
];

const cal = (values) => {
  return [
    values?.calories,
    values?.protein,
    values?.carbohydrates,
    values?.fiber,
    values?.fat,
    values?.vitamin,
    values?.iron,
    values?.potassium,
  ];
};

const EatCard2 = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const EatHealthy = recipes?.find((eat) => eat?.id === id);
  // we want to map EatHealthy

  const values = EatHealthy?.nutrition;
  const Percentage = cal(values);
  console.log(EatHealthy?.GlycemicIndex);
  return (
    <Container>
      <Box
        sx={{
          background: "#D2FF3A",
          py: "10px",
          borderRadius: "8px 8px  0 0",
        }}
      >
        <Typography
          className="font-weight-500 font-family-Work"
          sx={{ fontSize: { md: "18px", xs: "16px" }, textAlign: "center" }}
        >
          Nutrition per Serving (Approximate):
        </Typography>
      </Box>
      <Box
        sx={{
          border: "1px solid rgba(131, 164, 249, 0.15)",
          borderRadius: "0 0 8px 8px",
          p: { md: "10px", xs: "5px" },
        }}
      >
        <Grid container sx={{ display: "flex" }}>
          <Grid item xl={4} lg={4} md={4} xs={4} sm={4}>
            {Names.map((name, index) => (
              <Typography
                key={index}
                className="font-weight-400 font-family-Work"
                sx={{
                  pb: "5px",
                  "@media screen and (max-width: 483px)": {
                    fontSize: "12px",
                  },
                  "@media screen and (max-width: 382px)": {
                    fontSize: "10px",
                  },
                }}
              >
                {name}
              </Typography>
            ))}
          </Grid>
          <Grid item xl={8} lg={8} md={8} xs={8} sm={8}>
            {Percentage?.map((value, index) => (
              <Typography
                key={index}
                className="font-weight-400 font-family-Work"
                sx={{
                  pb: "5px",
                  "@media screen and (max-width: 483px)": {
                    fontSize: "12px",
                  },
                  "@media screen and (max-width: 382px)": {
                    fontSize: "10px",
                  },
                }}
              >
                {value}
              </Typography>
            ))}
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              background: "#FFF4F6",
              p: "10px",
            }}
          >
            <Typography
              sx={{ fontSize: "14px" }}
              className="font-weight-400 font-family-Work"
            >
              <span style={{ fontSize: "16px", fontWeight: 500 }}>Note:</span>{" "}
              Nutrition values may vary depending on the specific brands and
              quantities of ingredients used.
            </Typography>
          </Grid>
          {/* <Grid item xs={3}>
          {Percentage.map((value, index) => (
            <Typography
              key={index}
              className="font-weight-400 font-family-Work"
              sx={{pb:'5px'}}
            >
              {value}
            </Typography>
          ))}
        </Grid> */}
        </Grid>
      </Box>
      <Box
        sx={{
          border: "1px solid rgba(131, 164, 249, 0.15)",
          borderRadius: "8px",
          p: "10px",
          mt: "10px",
        }}
      >
        <Grid container>
          <Grid item xl={5.5} md={3.5} sm={3} xs={4.5}>
            <Typography
              className="font-weight-400 font-family-Work"
              sx={{ mb: "20px" }}
            >
              Glycemic Index
            </Typography>
            <Typography className="font-weight-400 font-family-Work">
              Glycemic Load
            </Typography>
          </Grid>
          <Grid
            item
            xl={4.5}
            md={6}
            sm={7}
            xs={3.5}
            sx={{ mt: { md: "0", xs: "15px" }, pl: { md: "0", xs: "10px" } }}
          >
            <Typography
              className="font-weight-400 font-family-Work"
              sx={{
                background: "#D2FF3A",
                borderRadius: "5px",
                textAlign: "center",
                mb: { sm: "10px", xs: "20px" },
                width: "44px",
                p: "5px",
              }}
            >
              {EatHealthy?.GlycemicIndex?.Score}
            </Typography>
            <Typography
              className="font-weight-400 font-family-Work"
              sx={{
                background: "#D2FF3A",
                borderRadius: "5px",
                textAlign: "center",
                width: "44px",
                p: "5px",
              }}
            >
              {EatHealthy?.GlycemicLoad?.Score}
            </Typography>
          </Grid>
          <Grid
            item
            xl={1}
            md={1.5}
            xs={1}
            sx={{ pl: "15px", mt: { sm: "0", xs: "20px" } }}
          >
            <Typography
              className="font-weight-400 font-family-Work"
              sx={{ mb: "30px", color: "#F24E1E" }}
            >
              {EatHealthy?.GlycemicIndex?.Level}
            </Typography>
            <Typography
              className="font-weight-400 font-family-Work"
              sx={{ pb: "5px", color: "#FFA412" }}
            >
              {EatHealthy?.GlycemicLoad?.Level}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              borderBottom: "1px solid rgba(131, 164, 249, 0.15)",
              mt: "20px",
            }}
          ></Grid>
        </Grid>
        <Grid container justifyContent={"space-between"} sx={{ pt: "30px" }}>
          <Grid item xs={6}>
            <Typography
              className="font-weight-400 font-family-Work"
              sx={{ mb: "15px" }}
            >
              Health Score
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              // justifyContent: "flex-end",
              position: "relative",
            }}
          >
            <Typography
              className="font-weight-400 font-family-Work"
              sx={{
                pb: "5px",
                color: "#F24E1E",
                mb: "15px",
                position: "absolute",
                right: "3rem",
              }}
            >
              {EatHealthy?.HealthScore}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default EatCard2;
