import React from "react";
import BlogInnerPage from "../features/blog-inner-page/BlogInnerPage";
import HeroSectionReusable from "../features/hero-section-reusable/hero-section-reuseable";
import BlogInnerPageBadge from "../features/blog-inner-page/BlogInnerPageBadge";
// import { useParams } from "react-router-dom";
import { BlogCardData } from "../features/blog/blogData";
import { useLocation } from "react-router-dom";

const BlogDetailPage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const blogPost = BlogCardData.find((post) => post.id === id);

  return (
    <>
      <HeroSectionReusable
        para={blogPost?.discription}
        heading={blogPost?.title}
        kolumhide={true}
      />
      <BlogInnerPageBadge BlogBadgeData={blogPost?.BlogBadgeData} />
      <BlogInnerPage blogPost={blogPost} />
    </>
  );
};

export default BlogDetailPage;
