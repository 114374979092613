// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_outTeam__\\+\\+3IV {
  padding: 80px 0;
  padding-bottom: 0;
}
.styles_outTeam__\\+\\+3IV .styles_heading__AKzeS {
  margin-bottom: 40px;
}
.styles_outTeam__\\+\\+3IV .styles_heading__AKzeS h5 {
  color: #ff611d !important;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 10px;
  text-transform: uppercase;
}
.styles_outTeam__\\+\\+3IV .styles_heading__AKzeS h2 {
  text-align: center;
  margin-bottom: 10px;
}
.styles_outTeam__\\+\\+3IV .styles_heading__AKzeS p {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0px;
  color: rgba(0, 0, 0, 0.8);
  max-width: 740px;
  text-align: center;
  margin: auto;
}`, "",{"version":3,"sources":["webpack://./src/features/career-page/firstSection/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,iBAAA;AACF;AACE;EACE,mBAAA;AACJ;AAAI;EACE,yBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,kBAAA;EAEA,mBAAA;EACA,yBAAA;AACN;AACI;EACE,kBAAA;EACA,mBAAA;AACN;AACI;EACE,eAAA;EACA,gBAAA;EAEA,iBAAA;EACA,mBAAA;EACA,yBAAA;EACA,gBAAA;EACA,kBAAA;EACA,YAAA;AAAN","sourcesContent":[".outTeam {\n  padding: 80px 0;\n  padding-bottom: 0;\n\n  .heading {\n    margin-bottom: 40px;\n    h5 {\n      color: #ff611d !important;\n      font-size: 16px;\n      font-weight: 600;\n      line-height: 20px;\n      letter-spacing: 0em;\n      text-align: center;\n\n      margin-bottom: 10px;\n      text-transform: uppercase;\n    }\n    h2 {\n      text-align: center;\n      margin-bottom: 10px;\n    }\n    p {\n      font-size: 16px;\n      font-weight: 400;\n\n      line-height: 28px;\n      letter-spacing: 0px;\n      color: #000000cc;\n      max-width: 740px;\n      text-align: center;\n      margin: auto;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"outTeam": `styles_outTeam__++3IV`,
	"heading": `styles_heading__AKzeS`
};
export default ___CSS_LOADER_EXPORT___;
