// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dialog-animation {
  animation: bounce2 1s linear forwards;
}

@keyframes bounce2 {
  0% {
    padding-bottom: 0px;
  }
  20% {
    padding-top: 150px;
  }
  40% {
    padding-top: 50px;
  }
  60% {
    padding-bottom: 180px;
  }
  80% {
    padding-top: 100px;
  }
  100% {
    padding: 0;
  }
}
.talk-to-us-form2 .MuiInputBase-root {
  padding: 0;
  border-radius: 8px;
}
.talk-to-us-form2 .MuiInputBase-root.Mui-focused fieldset {
  border-color: rgba(255, 164, 18, 0.45);
}
.talk-to-us-form2 .MuiInputBase-root .MuiInputBase-input {
  padding: 10px 16px;
  font-family: "Work Sans", sans-serif !important;
  color: #1e293b;
}
.talk-to-us-form2 .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
  border: 1.5px solid #e5e7eb;
}
.talk-to-us-form2 .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: rgba(255, 164, 18, 0.45);
}
.talk-to-us-form2 .submit-button {
  animation: changeBG 5s infinite;
}
@keyframes changeBG {
  25% {
    background-color: #1e293b;
  }
  50% {
    background-color: #ff611d;
  }
  75% {
    background-color: #1e293b;
  }
  100% {
    background-color: #ff611d;
  }
}`, "",{"version":3,"sources":["webpack://./src/features/gift-card/gift.scss"],"names":[],"mappings":"AAAA;EACE,qCAAA;AACF;;AAEA;EACE;IACE,mBAAA;EACF;EACA;IACE,kBAAA;EACF;EACA;IACE,iBAAA;EACF;EACA;IACE,qBAAA;EACF;EACA;IACE,kBAAA;EACF;EAEA;IACE,UAAA;EAAF;AACF;AAIE;EACE,UAAA;EACA,kBAAA;AAFJ;AAII;EACE,sCAAA;AAFN;AAKI;EACE,kBAAA;EACA,+CAAA;EACA,cAAA;AAHN;AAMI;EACE,2BAAA;AAJN;AAOI;EACE,sCAAA;AALN;AASE;EACE,+BAAA;AAPJ;AAUE;EACE;IACE,yBAAA;EARJ;EAUE;IACE,yBAAA;EARJ;EAUE;IACE,yBAAA;EARJ;EAUE;IACE,yBAAA;EARJ;AACF","sourcesContent":[".dialog-animation {\r\n  animation: bounce2 1s linear forwards;\r\n  //   padding-bottom:750px;\r\n}\r\n@keyframes bounce2 {\r\n  0% {\r\n    padding-bottom: 0px;\r\n  }\r\n  20% {\r\n    padding-top: 150px;\r\n  }\r\n  40% {\r\n    padding-top: 50px;\r\n  }\r\n  60% {\r\n    padding-bottom: 180px;\r\n  }\r\n  80% {\r\n    padding-top: 100px;\r\n  }\r\n\r\n  100% {\r\n    padding: 0;\r\n  }\r\n}\r\n\r\n.talk-to-us-form2 {\r\n  .MuiInputBase-root {\r\n    padding: 0;\r\n    border-radius: 8px;\r\n\r\n    &.Mui-focused fieldset {\r\n      border-color: rgba(255, 164, 18, 0.45);\r\n    }\r\n\r\n    .MuiInputBase-input {\r\n      padding: 10px 16px;\r\n      font-family: \"Work Sans\", sans-serif !important;\r\n      color: #1e293b;\r\n    }\r\n\r\n    .MuiOutlinedInput-notchedOutline {\r\n      border: 1.5px solid #e5e7eb;\r\n    }\r\n\r\n    &:hover .MuiOutlinedInput-notchedOutline {\r\n      border-color: rgba(255, 164, 18, 0.45);\r\n    }\r\n  }\r\n\r\n  .submit-button {\r\n    animation: changeBG 5s infinite;\r\n  }\r\n\r\n  @keyframes changeBG {\r\n    25% {\r\n      background-color: #1e293b;\r\n    }\r\n    50% {\r\n      background-color: #ff611d;\r\n    }\r\n    75% {\r\n      background-color: #1e293b;\r\n    }\r\n    100% {\r\n      background-color: #ff611d;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
