import React from "react";
import HeroSectionReusable from "../features/hero-section-reusable/hero-section-reuseable";
import image from "../assets/images/our-collection.gif";
// import OurCollectionCard from "../components/our-collecton-card/OurCollection";
import OurCollectionListing from "../features/our-collection-page/listing/OurCollectionListing";
// import { TalkToUsForm } from "../components/talk-to-us-form/talk-to-us-form";
// import { Container } from "@mui/material";

const OurCollection = () => {
  return (
    <>
      <HeroSectionReusable
        para={`At Share My Dine, we curate a diverse collection of dining experiences to suit every palate and occasion. Whether you're craving comfort food or seeking exotic flavors, our collection offers something special for everyone. Explore our carefully selected listings and discover unique dining destinations right at your fingertips.
`}
        heading={" Culinary Delights Await: Journey through Our Collection"}
        gifTitle={"Our Collections"}
        gifImage={image}
        hearthide={true}
      />
      <OurCollectionListing />

      {/* <TalkToUsForm /> */}
    </>
  );
};

export default OurCollection;
