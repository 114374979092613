import DiningExperiencesBG1 from "../../assets/images/home/dining-experiences-1.png";
import Chef1 from "../../assets/images/home/chef-2.png";
import DiningExperiencesBG2 from "../../assets/images/home/DE-BG-2.png";
import Chef2 from "../../assets/images/home/chef-3.png";
import DiningExperiencesBG3 from "../../assets/images/home/DE-BG-3.png";
import Chef3 from "../../assets/images/home/chef-4.png";
import DiningExperiencesBG4 from "../../assets/images/home/DE-BG-4.png";
import Chef4 from "../../assets/images/home/chef-5.png";
import DiningExperiencesBG5 from "../../assets/images/home/DE-BG-5.png";
import Chef5 from "../../assets/images/home/chef-6.png";
import DiningExperiencesBG6 from "../../assets/images/home/DE-BG-6.png";
import Chef6 from "../../assets/images/home/chef-7.png";
import DiningExperiencesBG7 from "../../assets/images/home/DE-BG-7.png";
import Chef7 from "../../assets/images/home/chef-8.png";
import DiningExperiencesBG8 from "../../assets/images/home/DE-BG-8.png";
import Chef8 from "../../assets/images/home/chef-9.png";
export const DININGEXPERIENCESDATA = [
  {
    BG: DiningExperiencesBG1,
    rating: 4.8,
    ratingCount: "(34)",
    title: "Donut-making workshop and tasting",
    desc: "Get hands-on experience making delicious, homemade donuts in a fun and interactive workshop.",
    city: "Croydon",
    hostAvatar: Chef1,
    hostName: "Mariza Gomez",
    pricePG: "50",
  },
  {
    BG: DiningExperiencesBG2,
    rating: 4.5,
    ratingCount: "(75)",
    title: "Artisanal cake tasting",
    desc: "Treat your taste buds to an indulgent evening of artisanal cakes, baked by a professional pastry chef. ",
    city: "Bond Street",
    hostAvatar: Chef2,
    hostName: "David Smith",
    pricePG: "48",
  },
  {
    BG: DiningExperiencesBG3,
    rating: 4.4,
    ratingCount: "(51)",
    title: "Authentic Italian feast",
    desc: "Indulge in a multi-course Italian meal featuring traditional dishes and family recipes passed down through generations.",
    city: "Paddington",
    hostAvatar: Chef3,
    hostName: "Luca Alpucci",
    pricePG: "90",
  },
  {
    BG: DiningExperiencesBG4,
    rating: 4.8,
    ratingCount: "(34)",
    title: "Authentic Punjabi cuisine @ Singh Di Dhaba",
    desc: "Indulge in a mouth-watering spread of authentic Punjabi dishes, cooked by a master of the art of Punjabi cuisine.",
    city: "Vauxhall",
    hostAvatar: Chef4,
    hostName: "Kuldeep Singh",
    pricePG: "30",
  },
  {
    BG: DiningExperiencesBG5,
    rating: 5.0,
    ratingCount: "(98)",
    title: "Seafood Under the Stars",
    desc: "Experience the magic of dining under the stars while indulging in a delectable seafood feast.",
    city: "Brighton",
    hostAvatar: Chef5,
    hostName: "Ryan Howard",
    pricePG: "50",
  },
  {
    BG: DiningExperiencesBG6,
    rating: 3.9,
    ratingCount: "(15)",
    title: "Farm-to-Table Brunch",
    desc: "Start your day off right with a farm-to-table brunch featuring fresh and seasonal ingredients.",
    city: "Camden Town",
    hostAvatar: Chef6,
    hostName: "Jacob William",
    pricePG: "30",
  },
  {
    BG: DiningExperiencesBG7,
    rating: 4.7,
    ratingCount: "(24)",
    title: "Sushi Making Workshop",
    desc: "Roll up your sleeves and learn the art of sushi making in a hands-on workshop led by a sushi chef.",
    city: "Acton",
    hostAvatar: Chef7,
    hostName: "Naomi Ken",
    pricePG: "68",
  },
  {
    BG: DiningExperiencesBG8,
    rating: 4.3,
    ratingCount: "(65)",
    title: "Mediterranean Cooking Class",
    desc: "Discover the flavours of the Mediterranean in a hands-on cooking class led by a local chef. Learn how to prepare classic dishes like hummus, falafel, and spanakopita.",
    city: "Ealing",
    hostAvatar: Chef8,
    hostName: "Adonis Vasileios",
    pricePG: "45",
  },
];
