import React from 'react'
import WorkComponent from '../features/work-with-us/work-with-us'

function WorkWithUs() {
  return (
    <>
    <WorkComponent/>
    </>
  )
}

export default WorkWithUs 