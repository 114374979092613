import React from "react";
import HeroSectionReusable from "../features/hero-section-reusable/hero-section-reuseable";
import EatHealthy from "../features/eat-healthy/EatHealthy";
import { useLocation } from "react-router-dom";
import { recipes } from "../features/eat-healthy/EatHealtyData";

const EatHealthyPage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const Eat = recipes.find((eat) => eat?.id === id);

  return (
    <>
      <HeroSectionReusable
        heading={Eat?.title}
        para={Eat?.subtitle}
        // eatHealthy
        kolumhide={true}
      />
      <EatHealthy />
    </>
  );
};

export default EatHealthyPage;
