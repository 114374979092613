// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/images/our-collection/blue-shade.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_parent__4LLbg {
  padding: 80px 0;
  padding-top: 180px;
}
.styles_parent__4LLbg::before {
  content: "";
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  position: absolute;
  width: 1000px;
  height: 900px;
  right: 200px;
  top: 700px;
  background-size: 100%;
  background-repeat: no-repeat;
}
@media screen and (max-width: 990px) {
  .styles_parent__4LLbg::before {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/features/our-collection-page/listing/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,kBAAA;AACF;AAAE;EACE,WAAA;EACA,yDAAA;EACA,kBAAA;EACA,aAAA;EACA,aAAA;EACA,YAAA;EACA,UAAA;EACA,qBAAA;EACA,4BAAA;AAEJ;AADI;EAVF;IAWI,aAAA;EAIJ;AACF","sourcesContent":[".parent {\n  padding: 80px 0;\n  padding-top: 180px;\n  &::before {\n    content: \"\";\n    background-image: url(\"../../../assets/images/our-collection/blue-shade.png\");\n    position: absolute;\n    width: 1000px;\n    height: 900px;\n    right: 200px;\n    top: 700px;\n    background-size: 100%;\n    background-repeat: no-repeat;\n    @media screen and (max-width: 990px) {\n      display: none;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"parent": `styles_parent__4LLbg`
};
export default ___CSS_LOADER_EXPORT___;
