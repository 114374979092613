import React from 'react'
import SingleChefComponent from '../features/single-chef/single-chef'

function SingleChef() {
  return (
 <>
 <SingleChefComponent/>
 </>
  )
}

export default SingleChef