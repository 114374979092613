import {
  Box,
  Button,
  Container,
  Grid,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import cloudImg from "../../../assets/images/work/feather_upload-cloud.png";
import ThemeButton from "../../../components/theme-button/theme-button";
import "../application-form/applicationform.scss";
import { FormProvider, useForm } from "react-hook-form";
import MuiAlert from "@mui/material/Alert";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Controller } from "react-hook-form";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";

const schema = yup.object().shape({
  firstName: yup.string()
  .matches(/^[a-zA-Z\s]+$/, {
    message: "Invalid First Name",
    excludeEmptyString: true,
  })
  .trim()
  .required("First Name is required"),
lastName: yup.string()
  .matches(/^[a-zA-Z\s]+$/, {
    message: "Invalid Last Name",
    excludeEmptyString: true,
  })
  .trim()
  .required("Last Name is required"),
  email: yup.string()
  .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, {
    message: "Invalid email format",
  })
  .required("Email is required"),
  phoneNumber: yup.string()
  .matches(/^[0-9+]+$/, {
    message: "Phone Number only contain numbers",
    excludeEmptyString: true,
  })
  .required("Phone Number is required"),

  // jobTitle: yup.string().required("Share Your Story  is required"),

  address: yup.string().required("Address is required"),
  dateOfBirth: yup.date()
  .nullable()
  .notRequired(),
  //   resume: yup.string().required("Subject is required"),
});

function ApplicationForm() {
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      address: "",
      dateOfBirth: null,
      // jobTitle: "",
      //   resume: "",
    },
  });
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = methods;
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [messageVariant, setMessageVariant] = React.useState("error");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const postUser = async (data) => {
    const body = data;
    try {
      const response = await fetch(
        `${process.env.REACT_APP_AAC_APP_BASE_URL}signup-leads`,
        {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            "x-api-key": `${process.env.REACT_APP_X_ACC_API_KEY}`,
          },
          body: JSON.stringify(data),
        }
      );
      const responseData = await response.json();
      if (responseData.statusCode === 201) {
        if (responseData.statusCode === 201) {
          setMessageVariant("success");
          setSnackbarMessage(
            responseData.message +
              ". A member of our team will be in touch with you shortly to discuss the next steps."
          );
          setSnackbarOpen(true);
          reset();
        } else {
          setError(responseData.message);
          setSnackbarMessage(responseData.message);
          setSnackbarOpen(true);
        }
      } else {
        setSnackbarMessage("API is not responding");
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage("An error occurred");
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
    reset?.();
  };

  const onSubmit = (data) => {
    console.log(data);

    postUser(data);
  };

  const StyledButton = styled(IconButton)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(1),
  }));

  return (
    <Container
      sx={{ maxWidth: "1696px !important" }}
      className="applicationfield"
    >
      <Container
        sx={{
          borderRadius: "8px",
          width: "100%",
          height: "auto",
          border: "4px solid #6B728017",
        }}
      >
        <Grid
          container
          sx={{
            maxWidth: "1578px",
            width: "100%",
            height: "auto",
            margin: "auto",
            //gap: '32px',
            padding: "50px",
            "@media screen and (max-width: 900px)": {
              padding: "20px",
            },
          }}
        >
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid item sx={{ width: "100%" }}>
                <Box>
                  <Typography
                    sx={{
                      fontFamily: "Work Sans",
                      fontWeight: 600,
                      fontSize: "32px",
                      lineHeight: "38.4px",
                      color: "#273142",
                      marginBottom: "2rem",
                      "@media screen and (max-width: 381px)": {
                        fontWeight: 600,
                        fontSize: "28px",
                        lineHeight: "28px",
                      },
                    }}
                  >
                    Application Form
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: "24px",
                    width: "100%",
                    flexWrap: { xs: "wrap", lg: "nowrap" },
                    marginBottom: "24px",
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <label
                      style={{
                        fontWeight: 500,
                        fontSize: "16px",
                        lineHeight: "20px",
                        color: "#4B5563",
                      }}
                    >
                      First Name<span style={{ color: "#FF0F0FD4" }}> *</span>
                    </label>
                    <TextField
                      fullWidth
                      {...register("firstName")}
                      sx={{ marginTop: "4px" }}
                      error={!!errors.firstName}
                      helperText={errors.firstName?.message}
                      FormHelperTextProps={{
                        sx: { marginLeft: "0px" }, 
                      }}
                    />
                  </Box>
                  <Box sx={{ width: "100%" }}>
                    <label
                      style={{
                        fontWeight: 500,
                        fontSize: "16px",
                        lineHeight: "20px",
                        color: "#4B5563",
                      }}
                    >
                      Last Name<span style={{ color: "#FF0F0FD4" }}> *</span>
                    </label>
                    <TextField
                      fullWidth
                      {...register("lastName")}
                      sx={{ marginTop: "4px" }}
                      error={!!errors.lastName}
                      helperText={errors.lastName?.message}
                      FormHelperTextProps={{
                        sx: { marginLeft: "0px" }, 
                      }}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: "24px",
                    width: "100%",
                    flexWrap: { xs: "wrap", lg: "nowrap" },
                    marginBottom: "24px",
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <label
                      style={{
                        fontWeight: 500,
                        fontSize: "16px",
                        lineHeight: "20px",
                        color: "#4B5563",
                      }}
                    >
                      Email<span style={{ color: "#FF0F0FD4" }}> *</span>
                    </label>
                    <TextField
                      fullWidth
                      {...register("email")}
                      sx={{ marginTop: "4px" }}
                      error={!!errors.email}
                      helperText={errors.email?.message}
                      FormHelperTextProps={{
                        sx: { marginLeft: "0px" }, 
                      }}
                    />
                  </Box>
                  <Box sx={{ width: "100%" }}>
                    <label
                      style={{
                        fontWeight: 500,
                        fontSize: "16px",
                        lineHeight: "20px",
                        color: "#4B5563",
                      }}
                    >
                      Phone No<span style={{ color: "#FF0F0FD4" }}> *</span>
                    </label>
                    <TextField
                      fullWidth
                      {...register("phoneNumber")}
                      sx={{ marginTop: "4px" }}
                      error={!!errors.phoneNumber}
                      helperText={errors.phoneNumber?.message}
                      FormHelperTextProps={{
                        sx: { marginLeft: "0px" }, 
                      }}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: "24px",
                    width: "100%",
                    flexWrap: { xs: "wrap", lg: "nowrap" },
                    marginBottom: "24px",
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <label
                      style={{
                        fontWeight: 500,
                        fontSize: "16px",
                        lineHeight: "20px",
                        color: "#4B5563",
                      }}
                    >
                      Address<span style={{ color: "#FF0F0FD4" }}> *</span>
                    </label>
                    <TextField
                      fullWidth
                      {...register("address")}
                      sx={{ marginTop: "4px" }}
                      error={!!errors.address}
                      helperText={errors.address?.message}
                      FormHelperTextProps={{
                        sx: { marginLeft: "0px" }, 
                      }}
                    />
                  </Box>
                  <Box sx={{ width: "100%" }}>
                    <label
                      style={{
                        fontWeight: 500,
                        fontSize: "16px",
                        lineHeight: "20px",
                        color: "#4B5563",
                      }}
                    >
                      Date of Birth
                      {/* <span style={{ color: "#FF0F0FD4" }}> *</span> */}
                    </label>
                    <Box
                      marginTop={0}
                      sx={
                        {
                          // width: "100%",
                        }
                      }
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Controller
                          name="dateOfBirth"
                          control={control}
                          fullWidth
                          render={({ field: { onChange, value } }) => (
                            <DatePicker
                              fullWidth
                              slots={{
                                openPickerButton: StyledButton,
                              }}
                              sx={{ marginTop: "4px", width: "100%" }}
                              // error={!!errors.dateOfBirth}
                              // helperText={errors.dateOfBirth?.message}
                              value={value}
                              onChange={onChange}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Box>
                  </Box>
                </Box>
                {/* <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    flexWrap: { xs: "wrap", lg: "nowrap" },
                    marginBottom: "24px",
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <label
                      style={{
                        fontWeight: 500,
                        fontSize: "16px",
                        lineHeight: "20px",
                        color: "#4B5563",
                      }}
                    >
                      Job Title <span style={{ color: "#FF0F0FD4" }}> *</span>
                    </label>
                    <TextField
                      fullWidth
                      {...register("jobTitle")}
                      sx={{ marginTop: "4px" }}
                      placeholder="Maximum 1000 character"
                      error={!!errors.jobTitle}
                      helperText={errors.jobTitle?.message}
                    />
                  </Box>
                </Box> */}
                <Box
                  sx={{
                    display: "flex",
                    //gap: "24px",
                    width: "100%",
                    flexWrap: { xs: "wrap", lg: "nowrap" },
                    marginBottom: "24px",
                  }}
                >
                  {/* <Box fullWidth sx={{ width: "100%", height: "auto" }}>
                    <label
                      style={{
                        fontWeight: 500,
                        fontSize: "16px",
                        lineHeight: "20px",
                        color: "#4B5563",
                      }}
                    >
                      Resume<span style={{ color: "#FF0F0FD4" }}> *</span>
                    </label>
                    <Box
                      sx={{
                        border: `1px dashed #00000040`,
                        height: "100%",
                        marginTop: "4px",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        "@media screen and (min-width: 600px)": {
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        },
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          "@media screen and (max-width: 600px)": {
                            marginTop: "15px",
                          },
                        }}
                      >
                        <img
                          src={cloudImg}
                          alt="Logo"
                          style={{ width: "48px", height: "48px " }}
                        />
                      </Box>
                      <Box
                        sx={{
                          margin: "15px 0px 25px 60px",
                          paddingRight: "10px",
                          "@media screen and (min-width: 600px)": {
                            margin: "0px 56px 0px 24px",
                          },
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: " Work Sans",
                            fontWeight: 400,
                            fontSize: "13px",
                            lineHeight: "15.25px",
                            color: "#273142",
                          }}
                        >
                          Select a file or drag and drop here
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: " Work Sans",
                            fontWeight: 400,
                            fontSize: "12px",
                            lineHeight: "14.08px",
                            color: "#00000066",
                          }}
                        >
                          JPG, PNG or PDF, file size no more than 10MB
                        </Typography>
                      </Box>
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Button
                          sx={{
                            fontFamily: " Work Sans",
                            fontWeight: 400,
                            fontSize: "14px",
                            lineHeight: "16.42px",
                            color: "#FF611D",
                            border: "1px solid",
                          }}
                        >
                          Select file
                        </Button>
                      </Box>
                    </Box>
                  </Box> */}
                </Box>
                <Box>
                  <Box
                    sx={{
                      height: "auto",
                      backgroundColor: "#FBFBFB",
                      padding: "16px",
                      borderRadius: "8px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: " Work Sans",
                        fontWeight: 400,
                        fontSize: "16px",
                        lineHeight: "28px",
                        color: "#6B7280",
                      }}
                    >
                      Join the Share My Dine community and turn your passion for
                      cooking into a rewarding experience. As a host, you'll
                      have the opportunity to share your culinary skills, meet
                      new people, and earn extra income.
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "24px",
                    }}
                  >
                    <ThemeButton
                      title="Submit Form"
                      type="submit"
                      sx={{ maxWidth: "373px" }}
                    />
                  </Box>
                </Box>
              </Grid>
            </form>
          </FormProvider>
        </Grid>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={5000}
          onClose={handleSnackbarClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            severity={messageVariant}
            sx={{ bgcolor: "#FF611D" }}
          >
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>
      </Container>
    </Container>
  );
}

export default ApplicationForm;
