import React, { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MuiAlert from "@mui/material/Alert";
import {
  Container,
  Divider,
  Typography,
  Snackbar,
} from "@mui/material";
import { ReactComponent as Phone } from "../../assets/svg/footer/phone-icon.svg";
import { ReactComponent as SMS } from "../../assets/svg/footer/sms-icon.svg";
import { ReactComponent as Patte } from "../../assets/svg/home/faqs-section-bg.svg";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import Person from "../../assets/images/home/talk-to-us.png";
import "./talk-to-us-form.scss";
import { Link } from "react-router-dom";
import { pageRoutes } from "../../routeList";
import * as yup from "yup";
import ThemeButton from "../theme-button/theme-button";

// CustomLabel Component
const CustomLabel = (props) => {
  const { label, required = false, marginBottom = 0.2 } = props;
  return (
    <Typography
      sx={{
        color: "#757575",
        marginBottom: `${marginBottom}rem`,
      }}
      variant="body2"
      fontWeight={500}
    >
      {label}
      {required && (
        <Typography color="red" component="span">
          {" "}
          *{" "}
        </Typography>
      )}
    </Typography>
  );
};

const schema = yup.object().shape({
  name: yup.string()
    .matches(/^[a-zA-Z\s]+$/, {
      message: "Invalid Name",
      excludeEmptyString: true,
    })
    .trim()
    .required("Name is required"),
    email: yup.string()
    .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, {
      message: "Invalid email format",
    })
    .required("Email is required"),
  phoneNumber: yup.string()
  .matches(/^[0-9+]+$/, {
    message: "Phone Number only contain numbers",
    excludeEmptyString: true,
  })
  .required("Phone Number is required"),

  query: yup.string().required("Subject is required"),
});

export const TalkToUsForm = () => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [messageVariant, setMessageVariant] = useState("error");
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      email: "",
      phoneNumber: "",
      query: "",
    },
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const postUser = async (data) => {
    const body = data;
    try {
      const response = await fetch(
        `${process.env.REACT_APP_AAC_APP_BASE_URL}enquiries`,
        {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            "x-api-key": `${process.env.REACT_APP_X_ACC_API_KEY}`,
          },
          body: JSON.stringify(data),
        }
      );
      const responseData = await response.json();
      if (responseData.statusCode === 201) {
        if (responseData.statusCode === 201) {
          setMessageVariant("success");
          setSnackbarMessage(
            responseData.message +
              " successfully! Thank you for reaching out. A member of our team will get back to you shortly."
          );
          setSnackbarOpen(true);
          reset();
        } else {
          setError(responseData.message);
          setSnackbarMessage(responseData.message);
          setSnackbarOpen(true);
        }
      } else {
        setSnackbarMessage("API is not responding");
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage("An error occurred");
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
    reset?.();
  };

  const onSubmit = (data) => {
    console.log(data);
    setLoading(true);
    postUser(data);
  };

  return (
    <Box padding={"80px 0"} paddingTop={"30px"}>
      <Container>
        <Box className="position-relative talk-to-us-form">
          <Patte
            className="position-absolute"
            width="188.822px"
            height="179.29px"
            style={{ top: "-10%", right: "80px", zIndex: 2 }}
          />
          <Box
            sx={{
              borderRadius: "24px",
              border: "4px solid #6B728017",
              boxShadow: "0px 65px 96px 0px rgba(100, 92, 170, 0.09)",
              zIndex: 4,
              bgcolor: "#fff",
              maxWidth: "990px",
              margin: "auto",
            }}
            padding={{ md: "12px 10px", xs: "15px 10px", xl: "60px" }}
            className="position-relative"
          >
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box
                  className="position-absolute"
                  left={"-78px"}
                  display={{ xs: "none", xl: "block" }}
                  bottom={"0%"}
                  zIndex={5}
                >
                  <img src={Person} alt="" />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "40px",
                    alignItems: "center",
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <div className="primary-heading blackish-color center-text font-weight-600">
                    Talk to Us!
                  </div>

                  <Box
                    sx={{
                      display: "flex",
                      gap: "2rem",
                      width: "100%",
                      flexWrap: { xs: "wrap", lg: "nowrap" },
                    }}
                  >
                    <Box sx={{ width: "100%" }}>
                      <CustomLabel label="Name" required={true} />
                      <TextField
                        {...register("name")}
                        error={!!errors.name}
                        helperText={errors.name?.message}
                        fullWidth
                        placeholder="Name"
                        sx={{
                          fontSize: "14px",
                        }}
                        FormHelperTextProps={{
                          sx: { marginLeft: "0px" }, 
                        }}
                      />
                    </Box>
                  </Box>
                  <Box sx={{ width: "100%" }}>
                    <CustomLabel label="Your Email" required={true} />
                    <TextField
                      {...register("email")}
                      error={!!errors.email}
                      helperText={errors.email?.message}
                      fullWidth
                      placeholder="Your Email"
                      type="email"
                      sx={{
                        fontSize: "14px",
                      }}
                       FormHelperTextProps={{
                        sx: { marginLeft: "0px" }, 
                      }}
                    />
                  </Box>
                  <Box sx={{ width: "100%" }}>
                    <CustomLabel label="Phone No" required={true} />
                    <TextField
                      {...register("phoneNumber")}
                      error={!!errors.phoneNumber}
                      helperText={errors.phoneNumber?.message}
                      fullWidth
                      placeholder="Phone No"
                      sx={{
                        fontSize: "14px",
                      }}
                       FormHelperTextProps={{
                        sx: { marginLeft: "0px" }, 
                      }}
                    />
                  </Box>
                  <Box sx={{ width: "100%" }}>
                    <CustomLabel label="Message" required={true} />
                    <TextField
                      {...register("query")}
                      error={!!errors.query}
                      helperText={errors.query?.message}
                      fullWidth
                      multiline
                      rows={4}
                      placeholder="Message"
                      sx={{
                        fontSize: "14px",
                      }}
                      FormHelperTextProps={{
                        sx: { marginLeft: "0px" }, 
                      }}
                    />
                  </Box>
                  <ThemeButton
                    type="submit"
                    title="Submit"
                    sx={{ maxWidth: "373px" }}
                  />
                </Box>
                <Divider className="margin-top-2">
                  <Box mx="40px">Or</Box>
                </Divider>
                <Box
                  className="flex width-100 margin-top-2"
                  justifyContent={"center"}
                  flexDirection={{ xs: "column", md: "row" }}
                  gap={"20px"}
                >
                  <div className="flex">
                    <Phone />
                    <div style={{ color: "#4B4B5F" }} className="margin-left-0">
                      Call Us: 0330 043 1210
                    </div>
                  </div>
                  <div className="flex">
                    <SMS />
                    <div style={{ color: "#4B4B5F" }} className="margin-left-0">
                      Chat with us: 09:00 AM - 05:00 PM
                    </div>
                  </div>
                </Box>
              </form>
            </FormProvider>
            <Box textAlign={"center"} marginTop={"40px"}>
              <Link to={pageRoutes.blog}>
                <Typography
                  color={"#FF611D"}
                  textAlign={"center"}
                  fontSize={"16px"}
                  fontStyle={"normal"}
                  fontWeight={"600"}
                  className="font-family-Work"
                  sx={{ cursor: "pointer" }}
                >
                  Checkout our blogs!
                </Typography>
              </Link>
            </Box>
          </Box>
        </Box>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={5000}
          onClose={handleSnackbarClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            severity={messageVariant}
            sx={{ bgcolor: "#FF611D" }}
          >
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>
      </Container>
    </Box>
  );
};
