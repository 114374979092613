// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.applicationfield .MuiInputBase-root {
  padding: 0;
  border-radius: 8px;
  height: 44px;
}
.applicationfield .MuiInputBase-root.Mui-focused fieldset {
  border-color: rgba(255, 164, 18, 0.45);
}
.applicationfield .MuiInputBase-root .MuiInputBase-input {
  padding: 10px 16px;
  font-family: "Work Sans", sans-serif !important;
  color: #1e293b;
}
.applicationfield .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
  border: 1.5px solid #e5e7eb;
}
.applicationfield .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: rgba(255, 164, 18, 0.45);
}`, "",{"version":3,"sources":["webpack://./src/features/work-with-us/application-form/applicationform.scss"],"names":[],"mappings":"AAGI;EACI,UAAA;EACA,kBAAA;EACA,YAAA;AAFR;AAIQ;EACE,sCAAA;AAFV;AAKQ;EACE,kBAAA;EACA,+CAAA;EACA,cAAA;AAHV;AAMQ;EACE,2BAAA;AAJV;AAOQ;EACE,sCAAA;AALV","sourcesContent":["\n.applicationfield{\n\n    .MuiInputBase-root {\n        padding: 0;\n        border-radius: 8px;\n        height: 44px;\n    \n        &.Mui-focused fieldset {\n          border-color: rgba(255, 164, 18, 0.45);\n        }\n    \n        .MuiInputBase-input {\n          padding: 10px 16px;\n          font-family: \"Work Sans\", sans-serif !important;\n          color: #1e293b;\n        }\n    \n        .MuiOutlinedInput-notchedOutline {\n          border: 1.5px solid #e5e7eb;\n        }\n    \n        &:hover .MuiOutlinedInput-notchedOutline {\n          border-color: rgba(255, 164, 18, 0.45);\n        }\n      }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
