export const pageRoutes = {
  home: "/",
  ourCollection: "/our-collection",
  careers: "/careers",
  BlogDetailPage: "/blog-detail-page",
  blog: "/blog",
  GiftCardPage: "/gift-card-page",
  WhatWeDo: "/what-we-do",
  Faqs: "/faqs",
  signup: "https://app.sharemydine.co.uk/authentication/signup",
  login: "https://app.sharemydine.co.uk/authentication/signin",
  privacyPolicy: "/privacy-policy",
  cookiePolicy: "/cookies-policies",
  termsConditions: "/terms-and-condition",
  ExploreRecipesPage: "/explore-recipes",
  exploreOurChef: "/explore-our-chef",
  singleChef: "/single-chef",
  EatHealthyPage: "/eat-healthy",
  ourSquad: "/our-squad",
  talkToUs: "/talk-to-us",
  WorkWithUs:'/work-with-us'
};
