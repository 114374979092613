import React from "react";
import Blog from "../features/blog";
import HeroSectionReusable from "../features/hero-section-reusable/hero-section-reuseable";
// import { TalkToUsForm } from "../components/talk-to-us-form/talk-to-us-form";
import headinggif from "../assets/blog/headinggif.gif";
// import { Container } from "@mui/material";

const BlogPage = () => {
  return (
    <div>
      <HeroSectionReusable
        heading={"Our Blogs "}
        gifTitle={"Chronicles"}
        gifImage={headinggif}
        para={`<p>Discover Insights and Stories: Our Blogs page features a collection of articles and musings penned by our team of experts and guest writers. From industry trends to personal reflections, delve into a wealth of knowledge and experiences. </p>
        
        
        `}
      />
      <Blog />

      {/* <TalkToUsForm /> */}
    </div>
  );
};

export default BlogPage;
