import oursquad1 from "../../../assets/our-squad/listing/12.jpg";
import oursquad2 from "../../../assets/our-squad/listing/8.jpeg";
import oursquad3 from "../../../assets/our-squad/listing/SMD - Sophie Walker.jpg";
import oursquad4 from "../../../assets/our-squad/listing/SMD - Ahmad Amer.jpg";
import oursquad5 from "../../../assets/our-squad/listing/SMD - Harry Thomas.jpg";
import oursquad6 from "../../../assets/our-squad/listing/SMD - Lucy Ellen.jpg";
import oursquad7 from "../../../assets/our-squad/listing/SMD - Isla Mae Harris.jpg";
import oursquad8 from "../../../assets/our-squad/listing/SMD - Henry Robert Clark.jpg";

export const ourSquadData = [
  {
    id: 2,
    title: "Faisal Naeem",
    discription: "Product Owner",
    image: oursquad1,
    textPosition: "center",
  },

  {
    id: 3,
    title: "Azam Altaf",
    // subTitle: "Rawalpindi, Pakistan",
    discription: "Compliance Officer",
    image: oursquad2,
    textPosition: "center",
  },
  {
    id: 5,
    title: "Sophie Walker",
    // subTitle: "Lahore, Pakistan",
    discription: "Customer Training Manager",
    image: oursquad3,
    textPosition: "center",
  },
  {
    id: 7,
    title: "Lucy Ellen White",
    // subTitle: "Lahore, Pakistan",
    discription: "Data Scientist",
    image: oursquad6,
    textPosition: "center",
  },
  {
    id: 8,
    title: "Isla Mae Harris",
    discription: "Client Onboarding Specialist",
    image: oursquad7,
    textPosition: "center",
  },
  
  {
    id: 6,
    title: "Harry Thomas",
    // subTitle: "Rawalpindi, Pakistan",
    discription: "Loyalty Program Manager",
    image: oursquad5,
    textPosition: "center",
  },

  
  
  {
    id: 1,
    title: "Henry Robert Clark",
    discription: "Financial Analyst",
    image: oursquad8,
    textPosition: "center",
  },
  {
    id: 4,
    title: "Ahmad Amer",
    // subTitle: "Rawalpindi, Pakistan",
    discription: "Project Manager",
    image: oursquad4,
    textPosition: "center",
  },
];
