import React from 'react'
import ExploreChef from '../features/explore-our-chef/explore-our-chef'


function ExploreOurChef() {
  return (
   <ExploreChef/>
  )
}

export default ExploreOurChef